<!-- Airline & Flight Number -->
<div class="airline-flight-number">
  {{ airline }} {{ flightNumber }}
</div>

<!-- Code Share -->
<div *ngIf="currentCodeShare" class="code-share">

  <!-- Code Share Label -->
  <span class="code-share-label">
    Also As:
  </span>

  <!-- Code Share Airline & Flight Number -->
  {{ currentCodeShare.airlineName }} {{ currentCodeShare.flightNumber }}

</div>