<!-- Content -->
<ng-container *ngIf="showContent">

  <!-- Destination & Airline Logo -->
  <div class="destination-airline-logo">

    <!-- Airline Logo -->
    <app-airline-logo class="airline-logo" [airlineCode]="airlineCode"></app-airline-logo>

    <!-- Destination -->
    <div class="destination">
      {{ destination }}
    </div>

  </div>

  <!-- Airline & Flight Number -->
  <div class="airline-flight-number">
    {{ airline }} {{ flightNumber }}
  </div>

  <!-- Boarding Status -->
  <div class="boarding-status">

    <!-- Bus Boarding Icon (if applicable) -->
    <img *ngIf="busBoarding" class="bus-boarding-icon" src="assets/icons/bus-boarding.svg">

    <!-- Boarding Status Text -->
    Now Boarding

  </div>

  <!-- Boarding Group -->
  <div class="boarding-group" [ngSwitch]="statusCode">

    <!-- Boarding Group Name (default) -->
    <ng-container *ngSwitchDefault>
      {{ boardingGroup }}
    </ng-container>

    <!-- Last Call Message -->
    <ng-container *ngSwitchCase="'LastCall'">
      Last Call. All Groups.
    </ng-container>

  </div>

  <!-- Biometric -->
  <div *ngIf="biometric" class="biometric">
    Biometric
  </div>

</ng-container>

<!-- No Flight Slideshow -->
<aoc-slideshow
  *ngIf="showNoFlightSlideshow && noFlightPlaylist"
  class="no-flight-slideshow"
  [playlist]="noFlightPlaylist">
</aoc-slideshow>