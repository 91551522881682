import { Component, OnInit } from '@angular/core';
import { map, mergeMap, Observable, timer } from 'rxjs';

import { ConfigService } from '@aoc/config';
import { ComponentId, SignComponent } from '@aoc/sign';

import { CheckInCounterDirectory } from '../check-in-counter-directory.model';
import { CheckInPosition } from 'src/app/shared/models/check-in-position.model';
import { CheckInCounterService } from 'src/app/shared/services/check-in-counter.service';

import { environment } from 'src/environments/environment';

@ComponentId('CheckInCounterDirectoryModule.StandardModule.StandardComponent')
@Component({
  selector: 'app-standard',
  templateUrl: './standard.component.html',
  styleUrls: ['./standard.component.scss']
})
export class StandardComponent extends SignComponent implements OnInit {

  checkInPositions: Observable<CheckInPosition[]>;
  terminal: string | undefined;
  title: string | undefined;

  private checkInCounterUpdateIntervalMs: number = environment.checkInCounterUpdateIntervalSeconds * 1000;

  constructor(
    private checkInCounterService: CheckInCounterService,
    private configService: ConfigService
  ) {
    super();

    this.checkInPositions = this.getCheckInPositions();
  }

  async ngOnInit(): Promise<any> {
    const config = await this.configService.get<CheckInCounterDirectory>({});

    this.terminal = config.terminal;
    this.title = config.title;
  }

  private compareCheckInPositions(checkInPositionA: CheckInPosition, checkInPositionB: CheckInPosition): number {
    const airlineA = checkInPositionA.AirlineName;
    const airlineB = checkInPositionB.AirlineName;

    return airlineA.localeCompare(airlineB);
  }

  private getCheckInPositions(): Observable<CheckInPosition[]> {
    return timer(0, this.checkInCounterUpdateIntervalMs).pipe(
      mergeMap(() => this.checkInCounterService.getCheckInPositions()),
      map(checkInPositions => this.sortCheckInPositions(checkInPositions))
    );
  }

  private sortCheckInPositions(checkInPositions: CheckInPosition[]): CheckInPosition[] {
    return checkInPositions.sort((a, b) => this.compareCheckInPositions(a, b));
  }

}
