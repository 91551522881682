<!-- Header -->
<app-header class="header" [title]="title"></app-header>

<!-- Header Row -->
<app-header-row class="header-row"></app-header-row>

<!-- Rows -->
<app-row
  *ngFor="let position of checkInPositions | async"
  class="row"
  [airline]="position.AirlineName"
  [airlineCode]="position.AirlineId"
  [counterEnd]="position.PositionEndId"
  [counterStart]="position.PositionStartId">
</app-row>

<!-- Footer -->
<app-footer class="footer" [terminal]="terminal"></app-footer>