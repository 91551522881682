import { Component, Input } from '@angular/core';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent {

  @Input() name: string | undefined;

  baseUrl: string = environment.terminalLogoBaseUrl;

}
