import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreUiModule } from '@aoc/core-ui';
import { ModuleId } from '@aoc/sign';

import { GatePierLowerWideComponent } from './gate-pier-lower-wide.component';
import { GatePierLowerWideRoutingModule } from './gate-pier-lower-wide-routing.module';
import { BoardingSharedModule } from '../shared/shared.module';

@ModuleId('BoardingModule.GatePierLowerWideModule')
@NgModule({
  declarations: [
    GatePierLowerWideComponent
  ],
  imports: [
    CommonModule,
    CoreUiModule,
    GatePierLowerWideRoutingModule,
    BoardingSharedModule
  ]
})
export class GatePierLowerWideModule { }