import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreUiModule } from '@aoc/core-ui';
import { FlightListModule } from '@aoc/flight-list';

import { FlightListComponent } from './components/flight-list/flight-list.component';
import { FlightListHeaderRowComponent } from './components/flight-list-header-row/flight-list-header-row.component';
import { FlightListRowComponent } from './components/flight-list-row/flight-list-row.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { PageMarkerComponent } from './components/page-marker/page-marker.component';
import { PaginatedFlightListComponent } from './components/paginated-flight-list/paginated-flight-list.component';

@NgModule({
  declarations: [
    FlightListComponent,
    FlightListHeaderRowComponent,
    FlightListRowComponent,
    FooterComponent,
    HeaderComponent,
    PageMarkerComponent,
    PaginatedFlightListComponent
  ],
  imports: [
    CommonModule,
    CoreUiModule,
    FlightListModule
  ],
  exports: [
    FlightListComponent,
    FlightListHeaderRowComponent,
    FlightListRowComponent,
    FooterComponent,
    HeaderComponent,
    PageMarkerComponent,
    PaginatedFlightListComponent
  ]
})
export class FidsSharedModule { }
