import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ModuleId } from '@aoc/sign';

import { GatePierRoutingModule } from './gate-pier-routing.module';

@ModuleId('EnhancedBoardingModule.GatePierModule')
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    GatePierRoutingModule
  ]
})
export class GatePierModule { }
