<!-- BOS Logo -->
<img class="bos-logo" src="{{getBostonLoganUrl()}}">

<!-- Page Marker -->
<app-page-marker
  *ngIf="showPageMarker"
  class="page-marker"
  [currentPage]="currentPage"
  [pageCount]="pageCount">
</app-page-marker>

<!-- Terminal -->
<div class="terminal">
  <div class="label">
    You are in Terminal
  </div>
  <div class="terminal-letter" [class]="terminalClass">
    {{ terminal }}
  </div>
</div>
