import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { GidsComponent } from './gids.component';
import { BoardingModule } from './boarding/boarding.module';
import { BoardingWideModule } from './boarding-wide/boarding-wide.module';

const routes: Routes = [
  {
    path: 'boarding',
    loadChildren: () => BoardingModule
  },
  {
    path: 'boarding-wide',
    loadChildren: () => BoardingWideModule
  },
  {
    path: 'standard',
    loadChildren: () => import('./standard/standard.module').then(m => m.StandardModule)
  },
  {
    path: 'video-bar',
    loadChildren: () => import('./video-bar/video-bar.module').then(m => m.VideoBarModule)
  },
  {
    path: 'video-bar-short',
    loadChildren: () => import('./video-bar-short/video-bar-short.module').then(m => m.VideoBarShortModule)
  },
  { path: ':id', component: GidsComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GidsRoutingModule { }
