import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { FlightTypeEnum } from '@aoc/data-models';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: { '[class]': 'flightType' }
})
export class HeaderComponent implements OnChanges {

  @Input() flightType: FlightTypeEnum | undefined;
  @Input() title: string | undefined;

  planeGraphicUrl: string | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    const flightType = changes['flightType']?.currentValue;

    if (changes['flightType']) {
      this.planeGraphicUrl = this.getPlaneGraphicUrl(flightType);
    }
  }

  private getPlaneGraphicUrl(flightType: FlightTypeEnum | undefined): string | undefined {
    let fileName;

    if (flightType === FlightTypeEnum.arrivals) {
      fileName = 'landing-plane';
    }

    if (flightType === FlightTypeEnum.departures) {
      fileName = 'departing-plane';
    }

    return fileName && `assets/images/${fileName}.svg`;
  }

}
