import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlightListModule } from '@aoc/flight-list';
import { ModuleId } from '@aoc/sign';

import { CustomsComponent } from './customs.component';
import { CustomsRoutingModule } from './customs-routing.module';
import { FidsSharedModule } from '../shared/shared.module';
import { SharedModule } from 'src/app/shared/shared.module';

@ModuleId('FidsModule.CustomsModule')
@NgModule({
  declarations: [
    CustomsComponent
  ],
  imports: [
    CommonModule,
    FlightListModule,
    CustomsRoutingModule,
    FidsSharedModule,
    SharedModule
  ]
})
export class CustomsModule { }
