<!-- COLUMN: Airport -->
<div class="col col-airport" [ngSwitch]="flightType">

  <!-- Arrivals -->
  <ng-container *ngSwitchCase="'arrivals'">
    {{ origin }}
  </ng-container>

  <!-- Departures -->
  <ng-container *ngSwitchCase="'departures'">
    {{ destination }}
  </ng-container>

</div>

<!-- COLUMN: Airline -->
<div class="col col-airline">

  <!-- Airline Logo -->
  <div class="airline-logo">
    <img *ngIf="showAirlineLogo" [src]="airlineLogoUrl" (error)="hideAirlineLogo">
  </div>

  <!-- Airline Name -->
  <div class="airline">
    {{ airline }}
  </div>

</div>

<!-- COLUMN: Flight -->
<div class="col col-flight">
  {{ flightNumber }}
</div>

<!-- COLUMN: Gate (departures only) -->
<div class="col col-gate" *ngIf="flightType === 'departures'">
  <div class="gate-walk-time" *ngIf="gate">

    <!-- Gate -->
    <div class="gate">
      {{ gate }}
    </div>

    <!-- Walk Time -->
    <div class="walk-time" *ngIf="walkTime">

      <!-- Left -->
      <ng-container *ngIf="walkTime.direction === 'l'">
        <img class="walk-icon" src="assets/icons/walk-left.svg">
        <img class="arrow-icon" src="assets/icons/arrow-left.svg">
      </ng-container>

      <!-- Right -->
      <ng-container *ngIf="walkTime.direction === 'r'">
        <img class="walk-icon" src="assets/icons/walk-right.svg">
        <img class="arrow-icon" src="assets/icons/arrow-right.svg">
      </ng-container>

      <!-- Walk Time Minutes -->
      <div class="walk-time-minutes">
        <div class="minutes">
          {{ walkTime.walkTimeMinutes }}
        </div>
        <div class="minutes-label">
          min
        </div>
      </div>

    </div>

  </div>
</div>

<!-- COLUMN: Claim (arrivals only) -->
<div class="col col-claim" *ngIf="flightType === 'arrivals'">

  <!-- Claim -->
  <div class="claim" *ngIf="claim">
    {{ claim }}
  </div>

</div>

<!-- COLUMN: Time -->
<div class="col col-time">
  {{ time | date:'h:mm' }}
  <span class="am-pm">
    {{ time | date:'a' }}
  </span>
</div>

<!-- COLUMN: Status -->
<div class="col col-status">
  {{ status }}
</div>