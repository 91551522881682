<!-- Slideshow -->
<aoc-slideshow *ngIf="playlist" class="slideshow" [playlist]="playlist"></aoc-slideshow>

<!-- YAH Overlay -->
<svg class="yah-overlay">

  <!-- YAH Marker -->
  <g
    class="yah-marker"
    [labelOffsetX]="yahLabelOffsetX"
    [labelOffsetY]="yahLabelOffsetY"
    [rotate]="yahRotate"
    [x]="yahX"
    [y]="yahY"
    app-yah-marker></g>

</svg>