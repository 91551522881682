import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Socket, io } from 'socket.io-client';

import { BoardingDoor } from '../enums/boarding-door.enum';
import { BoardingGroup } from '../models/boarding-group.model';
import { BoardingStatus } from '../models/boarding-status.model';

import { environment } from 'src/environments/environment';

@Injectable()
export class BoardingService {

  private boardingStatusSubject: Subject<BoardingStatus | undefined> = new Subject();
  private socket: Socket | undefined;
  private url: string = environment.boardingServiceUrl;

  getBoardingStatus(gate: string, flightId: string): Observable<BoardingStatus | undefined> {
    this.socket?.disconnect();

    const options = {
      query: { gate },
      transports: [ 'websocket' ]
    };

    const socket = io(this.url, options);

    socket.on('boarding-operation', boardingOperation => {
      let boardingStatus;

      if (boardingOperation.flightId === flightId) {
        boardingStatus = this.createBoardingStatus(boardingOperation);
      }

      this.boardingStatusSubject.next(boardingStatus);
    });

    this.socket = socket;

    return this.boardingStatusSubject.asObservable();
  }

  private createBoardingDoor(door: string): BoardingDoor | undefined {
    let boardingDoor;

    if (door === 'Left Door') {
      boardingDoor = BoardingDoor.Left;
    }

    if (door === 'Right Door') {
      boardingDoor = BoardingDoor.Right;
    }

    return boardingDoor;
  }

  private createBoardingGroups(boardingOperationMessages: any[]): BoardingGroup[] {
    const groups = new Map<string, BoardingGroup>();

    boardingOperationMessages.forEach(message => {
      const name = message.message;
      const boarding = message.active;
      const door = this.createBoardingDoor(message.door);

      let group = groups.get(name);

      if (group) {
        group.boarding = group.boarding || boarding;
      } else {
        group = { name, boarding, doors: [] };
      }

      if (boarding && door) {
        group.doors.push(door);
      }

      groups.set(name, group);
    });

    return Array.from(groups.values());
  }

  private createBoardingStatus(boardingOperation: any): BoardingStatus {
    const { boardingType, flightId, gate, messages, options, status } = boardingOperation;

    const biometric = boardingType?.name === 'Biometric';
    const boarding = status === 'Boarding' || status === 'LastCall' || status === 'Open';
    const boardingGroups = this.createBoardingGroups(messages);
    const busBoarding = options?.some((option: any) => option.name === 'Bus');
    const lastCall = status === 'LastCall';
    const statusCode = status;

    return { biometric, boarding, boardingGroups, busBoarding, flightId, gate, lastCall, statusCode };
  }

}
