import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription, takeUntil } from 'rxjs';

import { ConfigService } from '@aoc/config';
import { Flight, FlightTypeEnum, Playlist } from '@aoc/data-models';
import { FlightDataConfig, FlightDataService } from '@aoc/flight-data';
import { SignComponent } from '@aoc/sign';

import { Gids } from './gids.model';
import { DestinationBackgroundExtension } from '../shared/enums/destination-background-extension.enum';
import { BoardingStatus } from '../shared/models/boarding-status.model';
import { BoardingService } from '../shared/services/boarding.service';

@Component({
  template: ''
})
export class GidsBaseComponent extends SignComponent implements OnDestroy, OnInit {

  boardingStatus: BoardingStatus | undefined;
  boardingStatusSubscription: Subscription | undefined;
  destinationBackgroundExtension: DestinationBackgroundExtension = DestinationBackgroundExtension.PNG;
  enableBoardingStatus: boolean = false;
  flight: any;
  flightType: FlightTypeEnum = FlightTypeEnum.departures;
  gate: string | undefined;
  nextFlight: Flight | undefined;
  noFlightPlaylist: Playlist | undefined;
  promoPlaylist: Playlist | undefined;
  showNoFlightSlideshow: boolean = false;
  terminal: string | undefined;

  private unsubscribe: Subject<any> = new Subject();

  constructor(
    private boardingService: BoardingService,
    private configService: ConfigService,
    private flightDataService: FlightDataService
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
  }

  async ngOnInit(): Promise<any> {
    const config = await this.configService.get<Gids>({});

    const flightDataConfig = new FlightDataConfig({ configName: config.flightData.name });
    const terminal = config.terminal.toLowerCase();

    this.gate = config.gate;
    this.noFlightPlaylist = config.noFlightPlaylist;
    this.promoPlaylist = config.promoPlaylist;
    this.terminal = terminal;

    this.flightDataService.initConfig(flightDataConfig);

    this.flightDataService.getFlights()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(flights => this.onFlightsChange(flights));
  }

  private getBoardingStatus(gate: string | undefined, flightId: string | undefined): void {
    this.boardingStatusSubscription?.unsubscribe();

    if (gate && flightId) {
      const subscription = this.boardingService.getBoardingStatus(gate, flightId)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(boardingStatus => this.boardingStatus = boardingStatus);

      this.boardingStatusSubscription = subscription;
    } else {
      this.boardingStatus = undefined;
    }
  }

  private onFlightsChange(flights: Flight[]): void {
    const [ flight, nextFlight ] = flights;

    this.flight = flight;
    this.nextFlight = nextFlight;
    this.showNoFlightSlideshow = flights.length === 0;

    if (this.enableBoardingStatus) {
      this.getBoardingStatus(flight?.gate, flight?.flightHistoryId);
    }
  }

}