import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { StandardModule } from './standard/standard.module';

const routes: Routes = [
  {
    path: 'standard',
    loadChildren: () => StandardModule
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AirportShuttleRoutingModule { }
