import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {
  version: string = environment.version;
  compileDate: string = new Date().toLocaleDateString();
  author: string = 'Art of Context, Inc.';
  copyright: string = `© ${new Date().getFullYear()} Art of Context, Inc. All rights reserved.`;
  projectName: string = environment.projectName;
  constructor() { }

  ngOnInit(): void { }
}

