import { Pipe, PipeTransform } from '@angular/core';

import { terminalFromGate } from '../functions/terminal-from-gate.function';

@Pipe({ name: 'terminalFromGate' })
export class TerminalFromGatePipe implements PipeTransform {

  transform(gate: string | undefined): string | undefined {
    return gate && terminalFromGate(gate);
  }

}