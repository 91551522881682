import { Component, Input } from '@angular/core';

import { BoardingGroup } from 'src/app/shared/models/boarding-group.model';

@Component({
  selector: 'app-boarding-groups',
  templateUrl: './boarding-groups.component.html',
  styleUrls: ['./boarding-groups.component.scss']
})
export class BoardingGroupsComponent {

  @Input() boardingGroups: BoardingGroup[] = [];

}
