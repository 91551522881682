import { Component } from '@angular/core';

import { ComponentId } from '@aoc/sign';

import { BoardingBaseComponent } from '../boarding-base.component';
import { BoardingDoor } from 'src/app/shared/enums/boarding-door.enum';

@ComponentId('BoardingModule.GatePierLowerEscalatorModule.GatePierLowerEscalatorComponent')
@Component({
  selector: 'app-gate-pier-lower-escalator',
  templateUrl: './gate-pier-lower-escalator.component.html',
  styleUrls: ['./gate-pier-lower-escalator.component.scss']
})
export class GatePierLowerEscalatorComponent extends BoardingBaseComponent {

  door: BoardingDoor = BoardingDoor.Right;

}
