import { Component, OnDestroy, OnInit } from '@angular/core';
import { interval, Subject, takeUntil } from 'rxjs';

import { FlightDataConfig, FlightDataService } from '@aoc/flight-data';
import { ComponentId, SignComponent } from '@aoc/sign';

import { GidsConfigService } from './gids-config.service';

import { environment } from 'src/environments/environment';

@ComponentId('GidsModule.GidsComponent')
@Component({
  selector: 'app-gids',
  templateUrl: './gids.component.html',
  styleUrls: ['./gids.component.scss'],
  host: { '[class]': 'classList' }
})
export class GidsComponent extends SignComponent implements OnDestroy, OnInit {

  classList: string = '';
  codeShares: any[] = [];
  flight?: any;
  flightDataConfig: FlightDataConfig = new FlightDataConfig();
  gate: string = '';
  nextFlight?: any;
  showAirlineLogo: boolean = true;
  showAirlineName: boolean = false;
  showCodeShares: boolean = false;
  terminal: string = '';

  private unsubscribe: Subject<any> = new Subject();

  constructor(
    private configService: GidsConfigService,
    private flightDataService: FlightDataService
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
  }

  async ngOnInit(): Promise<any> {
    const config = await this.configService.getConfig();

    this.flightDataConfig = config.flightDataConfig;
    this.gate = config.gate;
    this.terminal = config.terminal;

    this.classList = this.getClassList();

    this.initFlightDataFeed();
    this.rotateCodeShares();
  }

  onAirlineLogoError(): void {
    this.showAirlineLogo = false;
    this.showAirlineName = true;
  }

  private getClassList(): string {
    const classList = [];

    const status = this.flight && this.flight.flightStatus;
    const terminal = this.terminal && this.terminal.toLowerCase();

    if (status) {
      classList.push(`status-${status}`);
    }

    if (terminal) {
      classList.push(`term-${terminal}`);
    }

    return classList.join(' ');
  }

  private initFlightDataFeed(): void {
    this.flightDataService.initConfig(this.flightDataConfig);

    this.flightDataService.getFlights()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(flights => this.onFlightsChange(flights));
  }

  private onFlightsChange(flights: any[]): void {
    const [ flight, nextFlight ] = flights;

    const codeShares = (flight && flight.codeShares) || [];

    this.codeShares = codeShares;
    this.flight = flight;
    this.nextFlight = nextFlight;
    this.showAirlineLogo = true;
    this.showAirlineName = false;
    this.showCodeShares = codeShares.length > 0;

    this.classList = this.getClassList();
  }

  private rotateCodeShares(): void {
    const rotateIntervalMs = environment.codeShareRotateIntervalMs;

    interval(rotateIntervalMs)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.codeShares.push(this.codeShares.shift()));
  }

}
