<!-- Gate -->
<div class="gate">
 {{terminal}}{{ gate }}
</div>

<!-- Flight -->
<div class="flight">
  <div *ngIf="flight" class="main">

    <!-- Airline & Flight Number -->
    <div class="airline-flight-number">

      <!-- Airline -->
      <div class="airline">

        <!-- Airline Logo -->
        <img *ngIf="showAirlineLogo" class="airline-logo" [src]="flight?.airlineLogoUrl" (error)="onAirlineLogoError()">

        <!-- Airline Name (fallback) -->
        <div *ngIf="showAirlineName" class="airline-name">
          {{ flight?.airlineName }}
        </div>

      </div>

      <!-- Flight Number -->
      <div class="flight-number">
        Flight # {{ flight?.flightNumber }}
      </div>

    </div>

    <!-- Destination -->
    <div class="destination">
      {{ flight?.destinationAirport }}
    </div>

    <!-- Scheduled Time & Status -->
    <div class="scheduled-time-status">

      <!-- Scheduled Time -->
      <div class="scheduled-time">
        <div class="label">
          Departs At:
        </div>
        <div class="value">
          {{ flight?.scheduledTime | date:'shortTime' }}
        </div>
      </div>

      <!-- Status -->
      <div class="status">
        {{ flight?.remarksWithTime }}
      </div>

    </div>

  </div>
  <div class="terminal-stripe">

    <!-- Code Shares Container-->
    <div *ngIf="showCodeShares" class="code-shares">

      <!-- Label -->
      <div class="label">
        Also Flying As:
      </div>

      <!-- Code Shares -->
      <div *ngFor="let codeShare of codeShares" class="code-share">

        <!-- Airline -->
        <div class="airline">

          <!-- Airline Logo -->
          <img
            #codeShareAirlineLogo
            class="airline-logo"
            src="https://signage-content.massport.cloud/airline-logos/airline-logos-200x36/{{ codeShare.airlineCode }}.gif"
            (error)="codeShareAirlineLogo.remove()">

          <!-- Airline Name (fallback) -->
          <div class="airline-name">
            {{ codeShare.airlineName }}
          </div>

        </div>

        <!-- Flight Number -->
        <div class="flight-number">
          Flight # {{ codeShare.flightNumber }}
        </div>

      </div>

    </div>

  </div>
</div>

<!-- Footer -->
<footer>

  <!-- Next Flight -->
  <div *ngIf="nextFlight" class="next-flight">
    Next Flight: {{ nextFlight.destinationAirport }},
    {{ nextFlight.airlineName }},
    Flight # {{ nextFlight.flightNumber }},
    Departing: {{ nextFlight.scheduledTime | date:'shortTime' }}
  </div>

  <!-- Current Time -->
  <aoc-now class="current-time" format="shortTime"></aoc-now>

</footer>
