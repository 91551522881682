import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, map, mergeMap, takeUntil, tap, timer } from 'rxjs';

import { ConfigService } from '@aoc/config';
import { Playlist } from '@aoc/data-models';
import { ComponentId, SignComponent } from '@aoc/sign';
import { IntervalConfig, IntervalData, IntervalService } from "@aoc/interval";

import { Curbside } from './curbside.model';
import { CheckInCurb } from '../shared/models/check-in-curb.model';
import { CheckInCounterService } from '../shared/services/check-in-counter.service';

import { environment } from 'src/environments/environment';

@ComponentId('CurbsideModule.CurbsideComponent')
@Component({
  selector: 'app-curbside',
  templateUrl: './curbside.component.html',
  styleUrls: ['./curbside.component.scss']
})
export class CurbsideComponent extends SignComponent implements OnDestroy, OnInit {

  backgroundPlaylist: Playlist | undefined;
  curbs: Array<CheckInCurb | undefined> = [];
  noDataPlaylist: Playlist | undefined;
  pageChangeCounter= 0;

  private updateIntervalSeconds: number = environment.checkInCounterUpdateIntervalSeconds || 60;
  private pageChangeIntervalSeconds: number = environment.curbsidePageChangeIntervalSeconds || 10;

  private intervalServiceUrl = environment.intervalServiceUrl;
  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private checkInCounterService: CheckInCounterService,
    private configService: ConfigService,
    private intervalService: IntervalService
  ) {
    super();
  }

  async ngOnInit(): Promise<any> {
    const config = await this.configService.get<Curbside>({}, this.id);

    const signCurbIds = this.getSignCurbIds(config);

    this.backgroundPlaylist = config.backgroundPlaylist;
    this.noDataPlaylist = config.noDataPlaylist;

    this.initCurbDataFeed(signCurbIds);
    this.initPagingInterval();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
  }

  private findCurbById(curbs: CheckInCurb[], id?: number): CheckInCurb | undefined {
    return curbs.find(curb => curb.id === id);
  }

  private getSignCurbIds(config: Curbside): Array<number | undefined> {
    const { curbIdSign1, curbIdSign2, curbIdSign3, curbIdSign4, curbIdSign5, curbIdSign6, curbIdSign7, curbIdSign8 } = config;

    return [ curbIdSign1, curbIdSign2, curbIdSign3, curbIdSign4, curbIdSign5, curbIdSign6, curbIdSign7, curbIdSign8 ];
  }

  private initCurbDataFeed(signCurbIds: Array<number | undefined>): void {
    const config = new IntervalConfig({
      serviceUrl: this.intervalServiceUrl,
      intervalMs: this.updateIntervalSeconds * 1000,
      intervalName: 'curbside-data',
      sync: false
    });

    this.intervalService.getInterval(config).pipe(
      mergeMap(() => this.checkInCounterService.getCurbsideCheckInPositions()),
      map(curbs => signCurbIds.map(id => this.findCurbById(curbs, id))),
      tap(curbs => this.curbs = curbs),
      takeUntil(this.unsubscribe)
    ).subscribe();

  }

  private initPagingInterval(): void {
    const config = new IntervalConfig({
      serviceUrl: this.intervalServiceUrl,
      intervalMs: this.pageChangeIntervalSeconds * 1000,
      intervalName: 'curbside-paging',
      sync: false
    });

    this.intervalService.getInterval(config)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((interval: IntervalData) => {
        this.pageChangeCounter = interval.tickNum;
      });
  }

}
