import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreUiModule } from '@aoc/core-ui';
import { ModuleId } from '@aoc/sign';

import { BehindBoardingDoorsComponent } from './behind-boarding-doors.component';
import { BehindBoardingDoorsRoutingModule } from './behind-boarding-doors-routing.module';
import { BoardingSharedModule } from '../shared/shared.module';

@ModuleId('BoardingModule.BehindBoardingDoorsModule')
@NgModule({
  declarations: [
    BehindBoardingDoorsComponent
  ],
  imports: [
    CommonModule,
    CoreUiModule,
    BehindBoardingDoorsRoutingModule,
    BoardingSharedModule
  ]
})
export class BehindBoardingDoorsModule { }