<!-- Airline Logo -->
<div class="airline-logo">
  <img *ngIf="showAirlineLogo" [src]="airlineLogoUrl" (error)="hideAirlineLogo()">
</div>

<!-- Content -->
<div class="content">
  <div class="content-top">
    
    <!-- Airport -->
    <div class="airport" [ngSwitch]="flightType">
      
      <!-- Arrivals -->
      <ng-container *ngSwitchCase="'arrivals'">
        {{ origin }}
      </ng-container>

      <!-- Departures -->
      <ng-container *ngSwitchCase="'departures'">
        {{ destination }}
      </ng-container>

    </div>

    <!-- Time -->
    <div class="time">
      {{ time | date:'h:mm' }}<span class="am-pm"> {{ time | date:'a' }}</span>
    </div>

  </div>
  <div class="content-bottom">
    
    <!-- Airline & Flight Number -->
    <div class="airline-flight-number">
      {{ airline }} {{ flightNumber }}
    </div>

    <!-- Status -->
    <div class="status">
      {{ status }}
    </div>

  </div>
</div>