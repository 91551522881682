<!-- BOS Logo -->
<app-logo class="bos-logo" name="boston-logan"></app-logo>

<!-- Terminal Label -->
<div class="terminal-label">
  You are in Terminal
</div>

<!-- Terminal -->
<div class="terminal" [class]="terminalClass">
  {{ terminal }}
</div>