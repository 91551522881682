import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ModuleId } from '@aoc/sign';

import { CheckInCounterDirectoryRoutingModule } from './check-in-counter-directory-routing.module';

@ModuleId('CheckInCounterDirectoryModule')
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CheckInCounterDirectoryRoutingModule
  ]
})
export class CheckInCounterDirectoryModule { }
