import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription, takeUntil } from 'rxjs';

import { ConfigService } from '@aoc/config';
import { Flight } from '@aoc/data-models';
import { FlightDataConfig, FlightDataService } from '@aoc/flight-data';
import { ComponentId, SignComponent } from '@aoc/sign';

import { BoardingPodiumSide } from './boarding-podium-side.model';
import { DestinationBackgroundExtension } from 'src/app/shared/enums/destination-background-extension.enum';
import { DestinationBackgroundSize } from 'src/app/shared/enums/destination-background-size.enum';
import { BoardingStatus } from 'src/app/shared/models/boarding-status.model';
import { BoardingService } from 'src/app/shared/services/boarding.service';

@ComponentId('EnhancedBoardingModule.BoardingPodiumSideModule.BoardingPodiumSideComponent')
@Component({
  selector: 'app-boarding-podium-side',
  templateUrl: './boarding-podium-side.component.html',
  styleUrls: ['./boarding-podium-side.component.scss']
})
export class BoardingPodiumSideComponent extends SignComponent implements OnDestroy, OnInit {

  boardingStatus: BoardingStatus | undefined;
  boardingStatusSubscription: Subscription | undefined;
  cityscapeBackgroundExtension: DestinationBackgroundExtension = DestinationBackgroundExtension.PNG;
  cityscapeBackgroundSize: DestinationBackgroundSize = DestinationBackgroundSize.VideoBarShort;
  defaultGate: string | undefined;
  flight: any;
  gate: string | undefined;
  nextFlight: Flight | undefined;

  private unsubscribe: Subject<any> = new Subject();

  constructor(
      private boardingService: BoardingService,
      private configService: ConfigService,
      private flightDataService: FlightDataService
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
  }

  async ngOnInit(): Promise<any> {
    const config = await this.configService.get<BoardingPodiumSide>({}, this.id);

    const flightDataConfig = new FlightDataConfig({ configName: config.flightData.name });

    this.defaultGate = config.gate;
    this.gate = config.gate;

    this.flightDataService.initConfig(flightDataConfig);

    this.flightDataService.getFlights()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(flights => this.onFlightsUpdate(flights));
  }

  private getBoardingStatus(gate: string, flightId: string): void {
    this.boardingStatusSubscription?.unsubscribe();

    if (gate && flightId) {
      const boardingStatusSubscription = this.boardingService.getBoardingStatus(gate, flightId)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(boardingStatus => this.boardingStatus = boardingStatus);

      this.boardingStatusSubscription = boardingStatusSubscription;
    }
  }

  private onFlightsUpdate(flights: Flight[]): void {
    const [ flight, nextFlight ] = flights;

    const gate = flight ? flight.gate : this.defaultGate;

    this.flight = flight;
    this.gate = gate;
    this.nextFlight = nextFlight;

    if (flight && flight.flightHistoryId != undefined) {
      this.getBoardingStatus(flight.gate, flight.flightHistoryId);
    } else {
      this.resetBoardingStatus();
    }
  }

  private resetBoardingStatus(): void {
    this.boardingStatusSubscription?.unsubscribe();

    this.boardingStatus = undefined;
  }

}
