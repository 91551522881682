import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ModuleId } from '@aoc/sign';

import { BoardingPodiumCenterComponent } from './boarding-podium-center.component';
import { BoardingPodiumCenterRoutingModule } from './boarding-podium-center-routing.module';
import { BoardingDoorComponent } from './boarding-door/boarding-door.component';
import { SharedModule } from 'src/app/shared/shared.module';

@ModuleId('EnhancedBoardingModule.BoardingPodiumCenterModule.BoardingPodiumCenterComponent')
@NgModule({
  declarations: [
    BoardingPodiumCenterComponent,
    BoardingDoorComponent
  ],
  imports: [
    CommonModule,
    BoardingPodiumCenterRoutingModule,
    SharedModule
  ]
})
export class BoardingPodiumCenterModule { }
