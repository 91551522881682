import { Component, OnInit } from '@angular/core';

import { ConfigService } from '@aoc/config';
import { FlightSort, FlightTypeEnum } from '@aoc/data-models';
import { FlightDataConfig } from '@aoc/flight-data';
import { ComponentId, SignComponent } from '@aoc/sign';

import { FidsCustoms } from './fids-customs.model';

@ComponentId('FidsModule.CustomsModule.CustomsComponent')
@Component({
  selector: 'app-customs',
  templateUrl: './customs.component.html',
  styleUrls: ['./customs.component.scss']
})
export class CustomsComponent extends SignComponent implements OnInit {

  flightDataConfig: FlightDataConfig = new FlightDataConfig();
  flightType: FlightTypeEnum = FlightTypeEnum.arrivals;
  pageNumber: number = 0;
  pageSize: number = 1;
  sortBy: FlightSort[] = [];

  constructor(
    private configService: ConfigService
  ) {
    super();
  }

  async ngOnInit(): Promise<any> {
    const config = await this.configService.get<FidsCustoms>({}, this.id);

    this.flightDataConfig = new FlightDataConfig({ configName: config.flightData.name });
    this.pageNumber = config.flightsView.pageNumber - 1;
    this.pageSize = config.flightsView.flightsPerPage;
    this.sortBy = config.flightsView.sortBy;
  }

}
