<!-- Header -->
<app-header class="header" [title]="title"></app-header>

<!-- Header Row -->
<app-header-row class="header-row"></app-header-row>

<!-- Row -->
<app-row
  *ngFor="let airline of rccAirlines"
  class="row"
  [airlineCode]="airline.airlineCode"
  [airlineName]="airline.airlineName"
  [domesticTerminal]="airline.domesticTerminal"
  [internationalTerminal]="airline.internationalTerminal">
</app-row>

<!-- Footer -->
<app-footer class="footer" [text]="footerText"></app-footer>