import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { Flight, FlightSort, FlightTypeEnum } from '@aoc/data-models';
import { FlightDataConfig } from '@aoc/flight-data';
import { FlightsPage, PagedFlightListComponent } from '@aoc/flight-list';

import { WalkTime } from 'src/app/shared/models/walk-time.model';

@Component({
  selector: 'app-paginated-flight-list',
  templateUrl: './paginated-flight-list.component.html',
  styleUrls: ['./paginated-flight-list.component.scss'],
  host: { '[class]': 'flightType' },
  animations: [
    trigger('pageChange', [
      state('true', style({
        transform: 'translateY(0)'
      })),
      state('false', style({
        transform: 'translateY(-100%)'
      })),
      transition('false => true', [
        style({ transform: 'translateY(100%)' }),
        animate('800ms ease-in-out')
      ]),
      transition('true => false', [
        animate('800ms ease-in-out')
      ])
    ])
  ]
})
export class PaginatedFlightListComponent implements AfterViewInit, OnDestroy {

  @Input() flightDataConfig: FlightDataConfig = new FlightDataConfig();
  @Input() flightType: FlightTypeEnum | undefined;
  @Input() maxPages: number = 1;
  @Input() pageChangeIntervalMs: number = 0;
  @Input() pageSize: number = 0;
  @Input() sortBy: FlightSort[] = [];
  @Input() walkTimes: WalkTime[] | null = null;

  @Output() pageschange: EventEmitter<FlightsPage[]> = new EventEmitter();

  @ViewChild(PagedFlightListComponent) flightList: PagedFlightListComponent | undefined;

  private unsubscribe: Subject<any> = new Subject();

  ngAfterViewInit(): void {
    this.flightList?.pages
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(pages => this.pageschange.emit(pages));
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
  }

  trackFlightById(index: number, flight: Flight): string {
    return flight.id;
  }

}
