export const environment = {
  production: true,
  projectName: require('../../package.json').name,
  version: require('../../package.json').version,
  airlineLogoBaseUrl: '//resources.bos.aocadp.com/mpa-sign/airline-logos',
  airportBusTimesServiceUrl: 'https://gtfs.bos.aocadp.com',
  boardingServiceUrl: 'https://bc-service.ga.massport.cloud/boarding',
  boardingInfoServiceUrl: 'https://bc-data.bos.aocadp.com',
  busTimesRotateIntervalSeconds: 15,
  busTimesUpdateIntervalSeconds: 10,
  airportShuttleTimesUpdateIntervalSeconds: 15,
  checkInCounterServiceUrl: 'https://checkin-data.bos.aocadp.com',
  checkInCounterUpdateIntervalSeconds: 60,
  codeShareRotateIntervalMs: 15000,
  curbsidePageChangeIntervalSeconds: 10,
  destinationBackgroundBaseUrl: '//resources.bos.aocadp.com/aoc/city-scapes',
  mbtaServiceUrl: 'https://mbta-proxy.bos.aocadp.com',
  rccAirlinesUpdateIntervalSeconds: 60,
  terminalLogoBaseUrl: '//resources.bos.aocadp.com/mpa-sign/logos',
  transitApproachingMinutes: 1,
  walkTimesBaseUrl: '//resources.bos.aocadp.com/mpa-sign/fids-location-walktimes',
  walkTimesUpdateIntervalMinutes: 10,

  intervalServiceUrl: 'https://flight-publish.bos.aocadp.com/interval',
  configOptions: {
    serviceUrl: 'https://config-service.bos.aocadp.com',
    configChangeCheckIntervalMs: 10000,
    messages: {
      ui: {
        background: 'rgba(3, 46, 147, 0.7)',
        textColor: '#ffffff',
        fontSize: '12vmin',
        fontFamily: '"Berthold Akzidenz Grotesk BE"',
        fontWeight: '700',
        textPadding: '12vmin',
        textTransform: 'uppercase',
        lineHeight: '13.5vmin',
        logoUrl: '/assets/logos/boston-logan.svg',
        logoHeight: '8vmin'
      }
    }
  },

  dmsDataConfig: {
    serviceUrl: 'https://feeds.massport.com/v2'
  },

  flightDataConfig: {
    serviceUrl: 'https://flight-publish.bos.aocadp.com/mpa/flights/datapublish'
  },
  slideshowSignConfig: {
    intervalServiceUrl: 'https://flight-publish.bos.aocadp.com/interval'
  },
  logConfig: {
    serviceUrl: 'https://graylog-prod-admin.aocdms.com/gelf',
    logLevel: 'Emergency',
    console: true
  },

  contentSecurityPolicy: `
    default-src
      'self'
    ;
    style-src
      'self'
      'unsafe-inline'
    ;
    img-src
      'self'
      blob:
      https://adp-resources.s3.amazonaws.com/
      https://adp-resources.s3.us-east-2.amazonaws.com/
      https://resources.bos.aocadp.com/
    ;
    media-src
      'self'
      blob:
      https://adp-resources.s3.amazonaws.com/
      https://adp-resources.s3.us-east-2.amazonaws.com/
      https://resources.bos.aocadp.com/
    ;
    connect-src
      'self'
      https://adp-resources.s3.amazonaws.com/
      https://adp-resources.s3.us-east-2.amazonaws.com/
      https://resources.bos.aocadp.com/
      https://config-service.bos.aocadp.com/
      https://flight-publish.bos.aocadp.com/
      wss://flight-publish.bos.aocadp.com/
      https://graylog-prod-admin.aocdms.com/
      https://airport-weather.aocadp.com/
      https://fp-data-publish-orh.docker.massport.cloud/
      wss://fp-data-publish-orh.docker.massport.cloud/
    ;
    frame-src
      https://bids.docker.massport.cloud
    ;
  `
};
