import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { environment } from "src/environments/environment";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnChanges {

  @Input() currentPage: number = 0;
  @Input() pageCount: number = 1;
  @Input() showPageMarker: boolean = false;
  @Input() terminal: string | undefined;

  terminalClass: string | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    const { terminal } = this;

    if (changes['terminal']) {
      this.terminalClass = this.getTerminalClass(terminal);
    }
  }

  private getTerminalClass(terminal: string | undefined): string | undefined {
    return terminal && `term-${terminal.toLowerCase()}`;
  }

  getBostonLoganUrl(): string | undefined {
    return environment.terminalLogoBaseUrl + "/boston-logan.svg";
  }

}
