<!-- Next Flight-->
<div *ngIf="nextFlight" class="next-flight">

  <!-- Next Flight Label -->
  <span class="next-flight-label">
    Next Flight:
  </span>

  <!-- Destination -->
  {{ nextFlight.destinationAirport }}

  <!-- Airline -->
  {{ nextFlight.airlineName }}

  <!-- Flight Number -->
  {{ nextFlight.flightNumber }}

  <!-- Scheduled Time -->
  <span class="scheduled-time">

    <!-- Hour & Minute -->
    <span class="hour-minute">
      {{ nextFlight.scheduledTime | date:'h:mm' }}
    </span>

    <!-- AM/PM -->
    <span class="am-pm">
      {{ nextFlight.scheduledTime | date:'a' }}
    </span>

  </span>

</div>