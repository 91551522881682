import { Component } from '@angular/core';

import { ComponentId } from '@aoc/sign';

import { BoardingBaseComponent } from '../boarding-base.component';
import { BoardingDoor } from 'src/app/shared/enums/boarding-door.enum';

@ComponentId('BoardingModule.GatePierLowerWideModule.GatePierLowerWideComponent')
@Component({
  selector: 'app-gate-pier-lower-wide',
  templateUrl: './gate-pier-lower-wide.component.html',
  styleUrls: ['./gate-pier-lower-wide.component.scss']
})
export class GatePierLowerWideComponent extends BoardingBaseComponent {

  door: BoardingDoor = BoardingDoor.Left;

}
