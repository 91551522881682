import { Injectable } from '@angular/core';
import { finalize, map, Observable, Subject } from 'rxjs';
import { io } from 'socket.io-client';

import { BoardingInfo } from '../models/boarding-info.model';

import { environment } from 'src/environments/environment';

@Injectable()
export class BoardingInfoService {

  private url: string = environment.boardingInfoServiceUrl;

  getBoardingInfo(gate: string): Observable<BoardingInfo> {
    const boardingInfo = new Subject<BoardingInfo>();
    const room = `gate${gate}`;

    const socket = io(this.url);

    socket.on('connect', () => socket.emit('join', room));
    socket.on('boardingInfo', message => boardingInfo.next(message));
    socket.on('disconnect', () => socket.off());

    return boardingInfo.asObservable().pipe(
      finalize(() => socket.disconnect())
    );
  }

  getBoardingMessages(gate: string): Observable<string[]> {
    return this.getBoardingInfo(gate).pipe(
      map(boardingInfo => this.getMessagesFromBoardingInfo(boardingInfo))
    );
  }

  private getDoorBoardingMessage(boardingMessage: string, showAsRows: boolean, firstRow: number, lastRow: number): string {
    return showAsRows ? `Rows ${firstRow} to ${lastRow}` : boardingMessage;
  }

  private getMessagesFromBoardingInfo(boardingInfo: BoardingInfo): string[] {
    const messages = [];

    if (boardingInfo.valid) {
      const { door1BoardingMessageIsRows, door1FirstRow, door1LastRow, door2BoardingMessageIsRows, door2FirstRow, door2LastRow } = boardingInfo;

      let { boardingStatus, door1BoardingMessage, door2BoardingMessage } = boardingInfo;

      door1BoardingMessage = this.getDoorBoardingMessage(door1BoardingMessage, door1BoardingMessageIsRows, door1FirstRow, door1LastRow);
      door2BoardingMessage = this.getDoorBoardingMessage(door2BoardingMessage, door2BoardingMessageIsRows, door2FirstRow, door2LastRow);
      boardingStatus = boardingStatus || 'Boarding';

      if (door1BoardingMessage) {
        messages.push(door1BoardingMessage);
      }

      if (door2BoardingMessage) {
        messages.push(door2BoardingMessage);
      }

      if (messages.length === 0) {
        messages.push(boardingStatus);
      }
    }

    return messages;
  }

}