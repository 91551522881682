import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ModuleId } from '@aoc/sign';

import { BidsRoutingModule } from './bids-routing.module';

@ModuleId('BidsModule')
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BidsRoutingModule
  ]
})
export class BidsModule { }
