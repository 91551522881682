import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreUiModule } from '@aoc/core-ui';
import { ModuleId } from '@aoc/sign';

import { GatePierUpperWideComponent } from './gate-pier-upper-wide.component';
import { GatePierUpperWideRoutingModule } from './gate-pier-upper-wide-routing.module';
import { BoardingSharedModule } from '../shared/shared.module';

@ModuleId('BoardingModule.GatePierUpperWideModule')
@NgModule({
  declarations: [
    GatePierUpperWideComponent
  ],
  imports: [
    CommonModule,
    CoreUiModule,
    GatePierUpperWideRoutingModule,
    BoardingSharedModule
  ]
})
export class GatePierUpperWideModule { }