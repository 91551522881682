<!-- Airline Logo -->
<app-airline-logo class="airline-logo" [airlineCode]="airlineCode"></app-airline-logo>

<!-- Main Content -->
<main>

  <!-- Destination -->
  <div class="destination">
    {{ destination }}
  </div>

  <!-- Airline & Flight Number -->
  <div class="airline-flight-number">
    {{ airline }} {{ flightNumber }}
  </div>

</main>