<!-- Airline -->
<div class="col col-airline">

  <!-- Airline Logo -->
  <app-airline-logo class="airline-logo" [airlineCode]="airlineCode"></app-airline-logo>

  <!-- Airline Name -->
  <div class="airline-name">
    {{ airline }}
  </div>

</div>

<!-- Counters -->
<div class="col col-counters">
  {{ counters }}
</div>