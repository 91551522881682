<!-- Gate -->
<div class="gate">

  <!-- Terminal -->
  <span class="terminal">
    {{ gate | terminalFromGate }}
  </span>

  <!-- Gate Number -->
  <span class="gate-number">
    {{ gate | gateNumberFromGate }}
  </span>

</div>