<div class="row">

  <!-- Airline Logo & Destination -->
  <div class="airline-logo-destination">

    <!-- Airline Logo -->
    <app-airline-logo class="airline-logo" [airlineCode]="airlineCode"></app-airline-logo>

    <!-- Destination -->
    {{ destination }}

  </div>

  <!-- Scheduled Time -->
  <div class="scheduled-time">

    <!-- Hour & Minute -->
    <span class="hour-minute">
      {{ scheduledTime | date:'h:mm' }}
    </span>

    <!-- AM/PM -->
    <span class="am-pm">
      {{ scheduledTime | date:'a' }}
    </span>

  </div>

</div>
<div class="row">

  <!-- Airline & Flight Number -->
  <div class="airline-flight-number">
    {{ airline }} {{ flightNumber}}
  </div>

  <!-- Code Share -->
  <div *ngIf="currentCodeShare" class="code-share">

    <!-- Label -->
    <span class="code-share-label">
      Also As:
    </span>

    {{ currentCodeShare.airlineName }} {{ currentCodeShare.flightNumber }}

  </div>

  <!-- Status -->
  <div class="status">
    {{ status }}
  </div>

</div>