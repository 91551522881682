<!-- Bus Icon -->
<img *ngIf="busBoarding" class="bus-icon" src="assets/icons/bus-boarding.svg">

<!-- Boarding Status -->
<div class="boarding-status">

  <!-- Boarding -->
  <div class="boarding">
    Boarding
  </div>

  <!-- Biometric -->
  <div *ngIf="biometric" class="biometric">
    Biometric
  </div>

</div>