import { Component } from '@angular/core';

import { FlightListRowBaseComponent } from '../flight-list-row/flight-list-row-base.component';

@Component({
  selector: 'app-flight-list-super-row',
  templateUrl: './flight-list-super-row.component.html',
  styleUrls: ['./flight-list-super-row.component.scss'],
  host: { 'class': 'flight-list-row', '[class]': 'classList' }
})
export class FlightListSuperRowComponent extends FlightListRowBaseComponent {
}
