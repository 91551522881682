import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { Flight } from '@aoc/data-models';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  host: { '[class]': 'terminalClass' }
})
export class FooterComponent implements OnChanges {

  @Input() nextFlight: Flight | undefined;
  @Input() terminal: string | undefined;

  terminalClass: string | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['terminal']) {
      const { terminal } = this;

      this.terminalClass = terminal && `term-${ terminal }`;
    }
  }

}
