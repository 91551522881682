<!-- Airline & Flight Number -->
<div class="airline-flight-number">

  <!-- Airline Logo -->
  <app-airline-logo class="airline-logo" [airlineCode]="airlineCode"></app-airline-logo>

  <!-- Airline Name & Flight Number -->
  <div class="airline-name-flight-number">
    {{ airline }} {{ flightNumber }}
  </div>

</div>

<!-- Destination -->
<div class="destination">
  {{ destination }}
</div>