import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreUiModule } from '@aoc/core-ui';
import { ModuleId } from '@aoc/sign';

import { EnplaneComponent } from './enplane.component';
import { EnplaneRoutingModule } from './enplane-routing.module';
import { FlightComponent } from './flight/flight.component';
import { SharedModule } from 'src/app/shared/shared.module';

@ModuleId('EnhancedBoardingModule.GatePierModule.EnplaneModule')
@NgModule({
  declarations: [
    EnplaneComponent,
    FlightComponent
  ],
  imports: [
    CommonModule,
    CoreUiModule,
    EnplaneRoutingModule,
    SharedModule
  ]
})
export class EnplaneModule { }
