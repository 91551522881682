import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ModuleId } from '@aoc/sign';

import { FidsConfigService } from './fids-config.service';
import { FidsRoutingModule } from './fids-routing.module';

@ModuleId('FidsModule')
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FidsRoutingModule
  ],
  providers: [
    FidsConfigService
  ]
})
export class FidsModule { }
