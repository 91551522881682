<!-- COLUMN: Airport -->
<div class="col col-airport">
  {{ airportColumnTitle }}
</div>

<!-- COLUMN: Airline -->
<div class="col col-airline">
  Airline
</div>

<!-- COLUMN: Flight -->
<div class="col col-flight">
  Flight
</div>

<!-- COLUMN: Gate (departures only) -->
<div class="col col-gate" *ngIf="flightType === 'departures'">
  Gate
</div>

<!-- COLUMN: Claim (arrivals only) -->
<div class="col col-claim" *ngIf="flightType === 'arrivals'">
  Claim
</div>

<!-- COLUMN: Time -->
<div class="col col-time">
  Time
</div>

<!-- COLUMN: Status -->
<div class="col col-status">
  Status
</div>