<!-- Boarding Status -->
<div *ngIf="boarding" class="boarding-status">

  <!-- Bus Icon (if bus boarding) -->
  <img *ngIf="busBoarding" class="bus-icon" src="assets/icons/bus-boarding.svg">

  <!-- Boarding Status Text -->
  <div class="boarding-status-text">
    Boarding
  </div>

</div>

<!-- Bracket -->
<img *ngIf="boarding" class="bracket" src="assets/icons/bracket-side.svg">

<!-- Boarding Groups -->
<div *ngIf="statusCode === 'Open' || statusCode === 'Boarding'" class="boarding-groups">

  <!-- Boarding Group -->
  <div *ngFor="let boardingGroup of boardingGroups" class="boarding-group" [class.boarding]="boardingGroup.boarding">
    {{ boardingGroup.name }}
  </div>

</div>

<!-- Last Call -->
<div *ngIf="statusCode === 'LastCall'" class="last-call">
  Last Call.<br>All Groups.
</div>

<!-- Closed -->
<div *ngIf="statusCode === 'Closed'" class="flight-closed">
  Flight Closed
</div>