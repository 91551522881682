import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-flight',
  templateUrl: './flight.component.html',
  styleUrls: ['./flight.component.scss']
})
export class FlightComponent {

  @Input() airline: string | undefined;
  @Input() airlineCode: string | undefined;
  @Input() destination: string | undefined;
  @Input() flightNumber: number | undefined;

}
