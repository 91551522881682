import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreUiModule } from '@aoc/core-ui';
import { ModuleId } from '@aoc/sign';

import { StandardComponent } from './standard.component';
import { StandardRoutingModule } from './standard-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';

@ModuleId('AirportShuttleModule.StandardModule')
@NgModule({
  declarations: [
    StandardComponent
  ],
  imports: [
    CommonModule,
    CoreUiModule,
    StandardRoutingModule,
    SharedModule
  ]
})
export class StandardModule { }
