import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnChanges {

  @Input() terminal: string | undefined;

  terminalClass: string | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['terminal']) {
      this.terminalClass = this.getTerminalClass(this.terminal);
    }
  }

  private getTerminalClass(terminal: string | undefined): string | undefined {
    return terminal && `term-${terminal.toLowerCase()}`;
  }

}
