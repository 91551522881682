import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subject, Subscription, takeUntil } from 'rxjs';

import { Flight } from '@aoc/data-models';
import { FlightDataConfig, FlightDataService, SocketService } from '@aoc/flight-data';

import { BoardingDoor } from 'src/app/shared/enums/boarding-door.enum';
import { BoardingStatusCode } from 'src/app/shared/enums/boarding-status-code.enum';
import { BoardingGroup } from 'src/app/shared/models/boarding-group.model';
import { BoardingStatus } from 'src/app/shared/models/boarding-status.model';
import { BoardingService } from 'src/app/shared/services/boarding.service';

@Component({
  selector: 'app-boarding-door',
  templateUrl: './boarding-door.component.html',
  styleUrls: ['./boarding-door.component.scss'],
  providers: [ BoardingService, FlightDataService, SocketService ],
  host: { '[class.hidden]': 'hidden' }
})
export class BoardingDoorComponent implements OnChanges, OnDestroy {

  @Input() door: BoardingDoor | undefined;
  @Input() flightDataConfig: FlightDataConfig = new FlightDataConfig();

  airline: string | undefined;
  airlineCode: string | undefined;
  biometric: boolean = false;
  boarding: boolean = false;
  boardingGroup: string | undefined;
  boardingStatus: BoardingStatus | undefined;
  busBoarding: boolean = false;
  destination: string | undefined;
  flightNumber: number | undefined;
  hidden: boolean = true;
  statusCode: BoardingStatusCode | undefined;

  private boardingStatusSubscription: Subscription | undefined;
  private flightsSubscription: Subscription | undefined;
  private unsubscribe: Subject<any> = new Subject();

  constructor(
      private boardingService: BoardingService,
      private flightDataService: FlightDataService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['door']) {
      const { boardingStatus, door } = this;

      this.onBoardingStatusOrDoorChange(boardingStatus, door);
    }

    if (changes['flightDataConfig']) {
      const { flightDataConfig } = this;

      this.getFlights(flightDataConfig);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
  }

  private getBoardingStatus(gate: string, flightId: string): void {
    this.boardingStatusSubscription?.unsubscribe();

    const subscription = this.boardingService.getBoardingStatus(gate, flightId)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(boardingStatus => this.onBoardingStatusOrDoorChange(boardingStatus, this.door));

    this.boardingStatusSubscription = subscription;
  }

  private getCurrentBoardingGroup(boardingStatus: BoardingStatus | undefined, door: BoardingDoor | undefined): BoardingGroup | undefined {
    let currentBoardingGroup;

    if (boardingStatus && door) {
      const boardingGroups = boardingStatus.boardingGroups;
      const lastIndex = boardingGroups.length - 1;

      for (let i = lastIndex; i >= 0 && !currentBoardingGroup; i--) {
        const group = boardingGroups[i];

        if (group.boarding && group.doors.includes(door)) {
          currentBoardingGroup = group;
        }
      }
    }

    return currentBoardingGroup;
  }

  private getFlights(flightDataConfig: FlightDataConfig): void {
    this.flightsSubscription?.unsubscribe();

    this.flightDataService.initConfig(flightDataConfig);

    const subscription = this.flightDataService.getFlights()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(flights => this.onFlightsChange(flights));

    this.flightsSubscription = subscription;
  }

  private onBoardingStatusOrDoorChange(boardingStatus: BoardingStatus | undefined, door: BoardingDoor | undefined): void {
    const currentBoardingGroup = this.getCurrentBoardingGroup(boardingStatus, door);
    const boarding = !!currentBoardingGroup;

    const statusCode = boardingStatus?.statusCode;
    const closed = statusCode === BoardingStatusCode.Closed;

    this.biometric = boarding && !!boardingStatus?.biometric;
    this.boarding = boarding;
    this.boardingGroup = currentBoardingGroup?.name;
    this.boardingStatus = boardingStatus;
    this.busBoarding = boarding && !!boardingStatus?.busBoarding;
    this.hidden = !boarding || closed;
    this.statusCode = statusCode;
  }

  private onFlightsChange(flights: Flight[]): void {
    const [ flight ] = flights;

    this.airline = flight?.airlineName;
    this.airlineCode = flight?.airlineCode;
    this.destination = flight?.destinationAirport;
    this.flightNumber = flight?.flightNumber;

    if (flight && flight.flightHistoryId != undefined) {
      this.getBoardingStatus(flight.gate, flight.flightHistoryId);
    } else {
      this.resetBoardingStatus();
    }
  }

  private resetBoardingStatus(): void {
    this.boardingStatusSubscription?.unsubscribe();

    this.onBoardingStatusOrDoorChange(undefined, this.door);
  }

}
