import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreUiModule } from '@aoc/core-ui';
import { ModuleId } from '@aoc/sign';

import { BoardingWideComponent } from './boarding-wide.component';
import { BoardingWideRoutingModule } from './boarding-wide-routing.module';
import { BoardingGroupsComponent } from './boarding-groups/boarding-groups.component';
import { BoardingStatusComponent } from './boarding-status/boarding-status.component';
import { FlightComponent } from './flight/flight.component';
import { FlightClosedComponent } from './flight-closed/flight-closed.component';
import { FooterComponent } from './footer/footer.component';
import { GateComponent } from './gate/gate.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { LastCallComponent } from './last-call/last-call.component';

@ModuleId('GidsModule.BoardingWideModule')
@NgModule({
  declarations: [
    BoardingGroupsComponent,
    BoardingStatusComponent,
    BoardingWideComponent,
    FlightComponent,
    FlightClosedComponent,
    FooterComponent,
    GateComponent,
    LastCallComponent
  ],
  imports: [
    CommonModule,
    CoreUiModule,
    BoardingWideRoutingModule,
    SharedModule
  ]
})
export class BoardingWideModule { }
