import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreUiModule } from '@aoc/core-ui';
import { ModuleId } from '@aoc/sign';

import { BoardingComponent } from './boarding.component';
import { BoardingRoutingModule } from './boarding-routing.module';
import { AirlineFlightNumberComponent } from './airline-flight-number/airline-flight-number.component';
import { BoardingStatusComponent } from './boarding-status/boarding-status.component';
import { DestinationAirlineLogoComponent } from './destination-airline-logo/destination-airline-logo.component';
import { FooterComponent } from './footer/footer.component';
import { GateComponent } from './gate/gate.component';
import { ScheduledTimeStatusComponent } from './scheduled-time-status/scheduled-time-status.component';
import { SharedModule } from 'src/app/shared/shared.module';

@ModuleId('GidsModule.BoardingModule')
@NgModule({
  declarations: [
    BoardingComponent,
    AirlineFlightNumberComponent,
    BoardingStatusComponent,
    DestinationAirlineLogoComponent,
    FooterComponent,
    GateComponent,
    ScheduledTimeStatusComponent
  ],
  imports: [
    CommonModule,
    CoreUiModule,
    BoardingRoutingModule,
    SharedModule
  ]
})
export class BoardingModule { }
