<aoc-slideshow
  class="promo-slideshow"
  *ngIf="promoPlaylist && flightList.rowCountCondition(0, showPromoRowCountUpperBound)"
  [playlist]="promoPlaylist">
</aoc-slideshow>
<aoc-flight-list
  class="flight-list"
  [flightDataConfig]="flightDataConfig"
  [pageNumber]="pageNumber"
  [pageSize]="pageSize"
  [sortBy]="sortBy"
  #flightList>
  <ng-template let-flight>
    <app-flight-list-row
      class="flight-list-row"
      [airline]="flight.airlineName"
      [airlineCode]="flight.airlineCode"
      [claim]="flight.baggage"
      [destination]="flight.destinationAirport"
      [flightNumber]="flight.flightNumber"
      [flightType]="flightType"
      [gate]="flight.gate"
      [origin]="flight.originAirport"
      [status]="flight.remarksWithTime"
      [statusCode]="flight.flightStatus"
      [terminal]="flight.terminal"
      [time]="flight.scheduledTime"
      [walkTimes]="walkTimes">
    </app-flight-list-row>
  </ng-template>
</aoc-flight-list>