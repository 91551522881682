import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';

import { TransitPredictionAdapter } from '../adapters/transit-prediction.adapter';
import { TransitStop } from '../enums/transit-stop.enum';
import { TransitPrediction } from '../models/transit-prediction.model';

import { environment } from 'src/environments/environment';

@Injectable()
export class TransitTimesService {

  constructor(
    private http: HttpClient,
    private transitPredictionAdapter: TransitPredictionAdapter
  ) {}

  getAirportBusTimes(terminal: string, claim: string): Observable<TransitPrediction[]> {
    const baseUrl = environment.airportBusTimesServiceUrl;
    const stop = this.getStop(terminal, claim);
    const collection = `term-${ stop }-arrivals`;
    const url = `${ baseUrl }/collections/${ collection }/prediction`;

    return this.http.get<any[]>(url).pipe(
      map(predictions => this.transitPredictionAdapter.fromAirportBusPredictions(predictions)),
      catchError(err => of([]))
    );
  }

  getAirportShuttleTimes(): Observable<TransitPrediction[]> {
    const baseUrl = environment.airportBusTimesServiceUrl;
    const collection = 'mbta';
    const url = `${ baseUrl }/collections/${ collection }/prediction`;

    return this.http.get<any[]>(url).pipe(
      map(predictions => this.transitPredictionAdapter.fromAirportBusPredictions(predictions)),
      catchError(err => of([]))
    );
  }

  getMbtaSilverLineBusTimes(terminal: string, claim: string): Observable<TransitPrediction[]> {
    const baseUrl = environment.mbtaServiceUrl;
    const stop = this.getStop(terminal, claim);
    const stopId = this.getMbtaStopId(stop);
    const sortBy = 'departure_time';
    const apiKey = 'e9bdbf5ac519418f98ec845029cfde96';

    const url =
      `${ baseUrl }/predictions?` +
      `filter[stop]=${ stopId }&` +
      `sort=${ sortBy }&` +
      `api_key=${ apiKey }`;

    return this.http.get<any>(url).pipe(
      map(res => res.data),
      map(predictions => this.transitPredictionAdapter.fromMbtaPredictions(predictions)),
      catchError(err => of([]))
    );
  }

  private getMbtaStopId(stop: TransitStop): string {
    const stops = {
      a: '17091',
      b1: '27092',
      b2: '17093',
      c: '17094',
      e: '17095'
    };

    return stops[stop];
  }

  private getStop(terminal: string, claim: string): TransitStop {
    let stop = terminal.toLowerCase();

    if (stop === 'b') {
      stop += this.getTerminalBStopNumber(claim);
    }

    return <TransitStop>stop;
  }

  private getTerminalBStopNumber(claim: string): number {
    return Number(claim) < 5 ? 1 : 2;
  }

}
