import { Injectable } from '@angular/core';

import { TransitPrediction } from '../models/transit-prediction.model';

import { environment } from 'src/environments/environment';

@Injectable()
export class TransitPredictionAdapter {

  fromAirportBusPrediction(airportBusPrediction: any): TransitPrediction {
    const minutes = airportBusPrediction.minutes;
    const approaching = this.isApproaching(minutes);
    const displayMinutes = this.getDisplayMinutes(minutes);
    const queryKey = airportBusPrediction.queryKey;
    const route = airportBusPrediction.route_id;
    const valid = airportBusPrediction.valid;

    const prediction = { approaching, displayMinutes, minutes, queryKey, route, valid };

    return prediction;
  }

  fromAirportBusPredictions(airportBusPredictions: any[]): TransitPrediction[] {
    console.log(airportBusPredictions)
    return airportBusPredictions
      .filter(prediction => new Date(prediction?.arrival_local) > new Date())
      .map(prediction => this.fromAirportBusPrediction(prediction));
  }

  fromMbtaPrediction(mbtaPrediction: any): TransitPrediction {
    const minutes = this.getMinutes(mbtaPrediction.attributes.departure_time);
    const approaching = this.isApproaching(minutes);
    const displayMinutes = this.getDisplayMinutes(minutes);
    const route = mbtaPrediction.relationships.route.data.id;
    const valid = true;

    const prediction = { approaching, displayMinutes, minutes, route, valid };

    return prediction;
  }

  fromMbtaPredictions(mbtaPredictions: any[]): TransitPrediction[] {
    return mbtaPredictions
      .filter(prediction => new Date(prediction?.attributes?.departure_time) > new Date())
      .map(prediction => this.fromMbtaPrediction(prediction));
  }

  private getDisplayMinutes(minutes: number): string {
    const approaching = this.isApproaching(minutes);

    return approaching ? 'Approaching' : `${ minutes } mins`;
  }

  private getMinutes(departureTime: string): number {
    const departs = new Date(departureTime).getTime();
    const now = new Date().getTime();
    const milliseconds = departs - now;
    const minutes = milliseconds / 1000 / 60;

    return Math.floor(minutes);
  }

  private isApproaching(minutes: number): boolean {
    const approachingMinutes = environment.transitApproachingMinutes || 0;

    return minutes <= approachingMinutes;
  }

}
