import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';

import {mergeMap, Observable, Subject, timer} from 'rxjs';

import {ComponentId, SignComponent} from '@aoc/sign';
import {ConfigService} from "@aoc/config";

import {TransitTimesService} from "../../shared/services/transit-times.service";
import {AirportShuttle} from '../airport-shuttle.model';
import {TransitPrediction} from "../../shared/models/transit-prediction.model";
import {environment} from "../../../environments/environment";

@ComponentId('AirportShuttle.StandardModule.StandardComponent')
@Component({
  selector: 'app-standard',
  templateUrl: './standard.component.html',
  styleUrls: ['./standard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StandardComponent extends SignComponent implements OnDestroy, OnInit {

  private unsubscribe: Subject<any> = new Subject();
  airportShuttleTimesUpdateIntervalSeconds: number = environment.airportShuttleTimesUpdateIntervalSeconds || 10;

  title = 'Next Airport Shuttle';
  route55NextBusText = '';
  route22NextBusText = '';
  route33NextBusText = '';
  route88NextBusText = '';
  route66NextBusText = '';

  constructor(
    private configService: ConfigService,
    private transitTimesService: TransitTimesService
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
  }

  async ngOnInit(): Promise<any> {
    const config = await this.configService.get<AirportShuttle>({});
    this.title = config.title || this.title;

    this.getAirportShuttleTimes().subscribe(predictions => {
      console.log('Predictions', predictions);

      this.route55NextBusText = this.getNextBusText(predictions.find(p => p.route === '55')?.displayMinutes);
      this.route22NextBusText = this.getNextBusText(predictions.find(p => p.route === '22')?.displayMinutes);
      this.route33NextBusText = this.getNextBusText(predictions.find(p => p.route === '33')?.displayMinutes);
      this.route66NextBusText = this.getNextBusText(predictions.find(p => p.route === '66')?.displayMinutes);
      this.route88NextBusText = this.getNextBusText(predictions.find(p => p.route === '88')?.displayMinutes);
    });
  }

  private getAirportShuttleTimes(): Observable<TransitPrediction[]> {
    const delay = this.airportShuttleTimesUpdateIntervalSeconds * 1000;

    return timer(0, delay).pipe(
      mergeMap(() => this.transitTimesService.getAirportShuttleTimes())
    );
  }

  private getNextBusText(minutes: string | undefined): string {
    if (minutes) {
      return minutes;
    } else {
      return '—';
    }
  }

}
