import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreUiModule } from '@aoc/core-ui';
import { MapModule } from '@aoc/map';

import { AirlineLogoComponent } from './components/airline-logo/airline-logo.component';
import { DestinationBackgroundComponent } from './components/destination-background/destination-background.component';
import { FlightListSuperRowComponent } from './components/flight-list-super-row/flight-list-super-row.component';
import { LogoComponent } from './components/logo/logo.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TerminalLogoComponent } from './components/terminal-logo/terminal-logo.component';
import { YahMarkerComponent } from './components/yah-marker/yah-marker.component';
import { BaggageClaimListPipe } from './pipes/baggage-claim-list.pipe';
import { GateNumberFromGatePipe } from './pipes/gate-number-from-gate.pipe';
import { TerminalFromGatePipe } from './pipes/terminal-from-gate.pipe';

@NgModule({
  declarations: [
    AirlineLogoComponent,
    DestinationBackgroundComponent,
    FlightListSuperRowComponent,
    LogoComponent,
    SidebarComponent,
    TerminalLogoComponent,
    YahMarkerComponent,
    BaggageClaimListPipe,
    GateNumberFromGatePipe,
    TerminalFromGatePipe
  ],
  imports: [
    CommonModule,
    CoreUiModule,
    MapModule
  ],
  exports: [
    AirlineLogoComponent,
    DestinationBackgroundComponent,
    FlightListSuperRowComponent,
    LogoComponent,
    SidebarComponent,
    TerminalLogoComponent,
    YahMarkerComponent,
    BaggageClaimListPipe,
    GateNumberFromGatePipe,
    TerminalFromGatePipe
  ]
})
export class SharedModule { }
