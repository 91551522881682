<!-- Background (default/no flight) -->
<img class="background layer" src="assets/images/bos-term-e_1920x1080.png">

<!-- Cityscape Background -->
<app-destination-background
  *ngIf="flight"
  class="cityscape-background layer"
  [airportCode]="flight.destinationAirportCode"
  [extension]="destinationBackgroundExtension"
  [size]="destinationBackgroundSize">
</app-destination-background>

<!-- Background Overlay -->
<div class="background-overlay layer"></div>

<!-- Content -->
<div class="content layer">

  <!-- Header -->
  <header>

    <!-- Gate -->
    <app-gate class="gate" [gate]="gate" [terminal]="terminal"></app-gate>

    <!-- Airline & Flight Number -->
    <app-airline-flight-number
      *ngIf="flight"
      class="airline-flight-number"
      [airline]="flight.airlineName"
      [codeShares]="flight.codeShares"
      [flightNumber]="flight.flightNumber">
    </app-airline-flight-number>

  </header>

  <!-- Destination & Airline Logo -->
  <app-destination-airline-logo
    *ngIf="flight"
    class="destination-airline-logo"
    [airlineCode]="flight.airlineCode"
    [destination]="flight.destinationAirport">
  </app-destination-airline-logo>

  <!-- Scheduled Time & Status -->
  <app-scheduled-time-status
    *ngIf="flight"
    class="scheduled-time-status"
    [scheduledTime]="flight.scheduledTime"
    [status]="flight.remarksWithTime">
  </app-scheduled-time-status>

  <!-- Boarding Status -->
  <app-boarding-status
    *ngIf="boardingStatus"
    class="boarding-status"
    [biometric]="boardingStatus.biometric"
    [boardingGroups]="boardingStatus.boardingGroups"
    [statusCode]="boardingStatus.statusCode">
  </app-boarding-status>

  <!-- Footer -->
  <app-footer class="footer" [nextFlight]="nextFlight" [terminal]="terminal"></app-footer>

  <!-- BOS Logo -->
  <img class="bos-logo" src="assets/logos/boston-logan.svg">

</div>

<!-- No Flight Slideshow -->
<aoc-slideshow
  *ngIf="showNoFlightSlideshow && noFlightPlaylist"
  class="no-flight-slideshow layer"
  [playlist]="noFlightPlaylist">
</aoc-slideshow>