import { Component, OnInit } from '@angular/core';

import { ConfigService } from '@aoc/config';
import { Playlist } from '@aoc/data-models';
import { ComponentId, SignComponent } from '@aoc/sign';

import { YahSlideshow } from '../yah-slideshow.model';

@ComponentId('YahSlideshowModule.StandardModule.StandardComponent')
@Component({
  selector: 'app-standard',
  templateUrl: './standard.component.html',
  styleUrls: ['./standard.component.scss']
})
export class StandardComponent extends SignComponent implements OnInit {

  playlist: Playlist | undefined;
  yahLabelOffsetX: number = 0;
  yahLabelOffsetY: number = 0;
  yahRotate: number = 0;
  yahX: number = 0;
  yahY: number = 0;

  constructor(
    private configService: ConfigService
  ) {
    super();
  }

  async ngOnInit(): Promise<any> {
    const config = await this.configService.get<YahSlideshow>({});

    this.playlist = config.playlist;
    this.yahLabelOffsetX = config.labelOffsetX || 0;
    this.yahLabelOffsetY = config.labelOffsetY || 0;
    this.yahRotate = config.markerRotationAngle || 0;
    this.yahX = config.markerPositionX || 0;
    this.yahY = config.markerPositionY || 0;
  }

}
