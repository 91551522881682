import { Component, Input } from '@angular/core';

import { FlightSort, FlightTypeEnum, Playlist } from '@aoc/data-models';
import { FlightDataConfig } from '@aoc/flight-data';

import { WalkTime } from 'src/app/shared/models/walk-time.model';

@Component({
  selector: 'app-flight-list',
  templateUrl: './flight-list.component.html',
  styleUrls: ['./flight-list.component.scss'],
  host: { 'class': 'flight-list', '[class]': 'flightType' }
})
export class FlightListComponent {

  @Input() flightDataConfig: FlightDataConfig = new FlightDataConfig();
  @Input() flightType: FlightTypeEnum | undefined;
  @Input() pageNumber: number = 0;
  @Input() pageSize: number = 0;
  @Input() promoPlaylist: Playlist | undefined;
  @Input() showPromoRowCountUpperBound: number = 0;
  @Input() sortBy: FlightSort[] = [];
  @Input() walkTimes: WalkTime[] | null = [];

}
