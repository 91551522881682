<!-- Header -->
<app-header class="header" title="Flights" [flightType]="flightType"></app-header>

<!-- Header Row -->
<div class="header-row row">
  <div class="col col-origin">
    Origin
  </div>
  <div class="col col-airline">
    Airline
  </div>
  <div class="col col-flight-number">
    Flight
  </div>
  <div class="col col-claim">
    Claim
  </div>
</div>

<!-- Flight List -->
<aoc-flight-list
  class="flight-list"
  [flightDataConfig]="flightDataConfig"
  [pageNumber]="pageNumber"
  [pageSize]="pageSize"
  [sortBy]="sortBy">
  <ng-template let-flight>

    <!-- Row -->
    <div class="row">

      <!-- Origin -->
      <div class="col col-origin">
        <span class="text-overflow-ellipsis">
          {{ flight.originAirport }}
        </span>
      </div>

      <!-- Airline -->
      <div class="col col-airline">

        <!-- Airline Logo -->
        <app-airline-logo class="airline-logo" [airlineCode]="flight.airlineCode"></app-airline-logo>

        <!-- Airline Name -->
        <div class="airline text-overflow-ellipsis">
          {{ flight.airlineName }}
        </div>

      </div>

      <!-- Flight Number -->
      <div class="col col-flight-number">
        {{ flight.flightNumber }}
      </div>

      <!-- Claim -->
      <div class="col col-claim">
        <div class="claim">
          {{ flight.baggage }}
        </div>
      </div>

    </div>

  </ng-template>
</aoc-flight-list>

<!-- Footer -->
<footer>

  <!-- BOS Logo -->
  <img class="bos-logo" src="assets/logos/boston-logan.svg">

  <!-- Terminal Label -->
  <div class="terminal-label">
    You are in Terminal
  </div>

  <!-- Terminal -->
  <div class="terminal">
    E
  </div>

</footer>