<!-- Boarding Status -->
<div class="boarding-status">

  <!-- Biometric -->
  <div *ngIf="biometric" class="biometric">
    Biometric
  </div>

  <!-- Boarding -->
  <div class="boarding">
    Boarding
  </div>

</div>

<!-- Boarding Message -->
<div *ngIf="boardingMessage" class="boarding-message">

  <!-- Bracket Icon -->
  <img class="bracket-icon" src="assets/icons/boarding-bracket-short.svg">

  <!-- Message -->
  <div class="message">
    {{ boardingMessage }}
  </div>

</div>