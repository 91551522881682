import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-boarding-status',
  templateUrl: './boarding-status.component.html',
  styleUrls: ['./boarding-status.component.scss']
})
export class BoardingStatusComponent {

  @Input() biometric: boolean = false;
  @Input() boarding: boolean = false;
  @Input() busBoarding: boolean = false;

}
