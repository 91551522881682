import { Component } from '@angular/core';

import { ComponentId } from '@aoc/sign';

import { GatePierBaseComponent } from '../gate-pier-base.component';

@ComponentId('EnhancedBoardingModule.GatePierModule.EnplaneModule.EnplaneComponent')
@Component({
  selector: 'app-enplane',
  templateUrl: './enplane.component.html',
  styleUrls: ['./enplane.component.scss']
})
export class EnplaneComponent extends GatePierBaseComponent {
}
