import { Component } from '@angular/core';

@Component({
  selector: 'app-header-row',
  templateUrl: './header-row.component.html',
  styleUrls: ['./header-row.component.scss'],
  host: { 'class': 'row' }
})
export class HeaderRowComponent {
}
