<!-- Left Door -->
<app-boarding-door
  class="boarding-door"
  [door]="leftDoor"
  [flightDataConfig]="flightDataConfig"
  [noFlightPlaylist]="noFlightPlaylist">
</app-boarding-door>

<!-- Right Door -->
<app-boarding-door
  class="boarding-door"
  [door]="rightDoor"
  [flightDataConfig]="flightDataConfig"
  [noFlightPlaylist]="noFlightPlaylist">
</app-boarding-door>