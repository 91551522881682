import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';

import { CheckInCurb } from '../models/check-in-curb.model';
import { CheckInPosition } from '../models/check-in-position.model';

import { environment } from 'src/environments/environment';

@Injectable()
export class CheckInCounterService {

  private baseUrl: string = environment.checkInCounterServiceUrl;

  constructor(
    private http: HttpClient
  ) {}

  getCheckInPositions(): Observable<CheckInPosition[]> {
    return this.http.get<CheckInPosition[]>(this.baseUrl).pipe(
      catchError(err => of([]))
    );
  }

  getCurbsideCheckInPositions(): Observable<CheckInCurb[]> {
    const url = `${ this.baseUrl }/curb`;

    return this.http.get<CheckInCurb[]>(url).pipe(
      catchError(err => of([]))
    );
  }

}