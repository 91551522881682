import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { BoardingDoor } from 'src/app/shared/enums/boarding-door.enum';
import { BoardingInfo } from 'src/app/shared/models/boarding-info.model';

@Component({
  selector: 'app-boarding-message',
  templateUrl: './boarding-message.component.html',
  styleUrls: ['./boarding-message.component.scss'],
  host: { '[class.uppercase]': 'uppercase' }
})
export class BoardingMessageComponent implements OnChanges {

  @Input() uppercase: boolean = true;
  @Input() boardingInfo: BoardingInfo | undefined;
  @Input() door: BoardingDoor | undefined;

  message: string | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['boardingInfo'] || changes['door']) {
      const { boardingInfo, door } = this;

      this.message = boardingInfo && door && this.getMessage(boardingInfo, door);
    }
  }

  private getMessage(boardingInfo: BoardingInfo, door: BoardingDoor): string {
    const { boardingStatus } = boardingInfo;

    const boardingMessage = boardingInfo[`door${ door }BoardingMessage`];
    const showAsRows = boardingInfo[`door${ door }BoardingMessageIsRows`];

    let message = boardingMessage || boardingStatus || 'Boarding';

    if (showAsRows) {
      const firstRow = boardingInfo[`door${ door }FirstRow`];
      const lastRow = boardingInfo[`door${ door }LastRow`];

      message = this.getMessageAsRows(firstRow, lastRow);
    }

    return message;
  }

  private getMessageAsRows(firstRow: number, lastRow: number): string {
    return `Rows ${ firstRow } - ${ lastRow }`;
  }

}
