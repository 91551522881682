import { Injectable } from '@angular/core';

import { ConfigService } from '@aoc/config';
import { GidsApiConfig } from './gids-api-config.model';

import { GidsConfig } from './gids-config.model';

@Injectable()
export class GidsConfigService {

  constructor(
    private configService: ConfigService
  ) {}

  async getConfig(): Promise<GidsConfig> {
    const config = await this.configService.get<GidsApiConfig>({});

    return new GidsConfig(config);
  }

}