import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModuleId } from '@aoc/sign';

import { BoardingRoutingModule } from './boarding-routing.module';

@ModuleId('BoardingModule')
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BoardingRoutingModule
  ]
})
export class BoardingModule { }
