import { FlightSort, FlightTypeEnum } from '@aoc/data-models';
import { FlightDataConfig } from '@aoc/flight-data';

import { FidsApiConfig } from './fids-api-config.model';

export class FidsConfig {

  flightDataConfig: FlightDataConfig;
  flightType: FlightTypeEnum;
  pageNumber: number;
  pageSize: number;
  sortBy: FlightSort[];
  terminal: string;

  constructor(config: FidsApiConfig) {
    const { flightData, flightsView, flightType } = config;

    const flightDataConfigName = flightData && flightData.name;
    const pageNumber = flightsView && flightsView.pageNumber;

    this.flightDataConfig = new FlightDataConfig({ configName: flightDataConfigName });
    this.flightType = flightType && flightType.type;
    this.pageNumber = pageNumber > 0 ? pageNumber - 1 : 0;
    this.pageSize = flightsView && flightsView.flightsPerPage;
    this.sortBy = (flightsView && flightsView.sortBy) || [];
    this.terminal = config.terminal;
  }

}