import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreUiModule } from '@aoc/core-ui';
import { ModuleId } from '@aoc/sign';

import { GatePierLowerEscalatorComponent } from './gate-pier-lower-escalator.component';
import { GatePierLowerEscalatorRoutingModule } from './gate-pier-lower-escalator-routing.module';
import { BoardingSharedModule } from '../shared/shared.module';

@ModuleId('BoardingModule.GatePierLowerEscalatorModule')
@NgModule({
  declarations: [
    GatePierLowerEscalatorComponent
  ],
  imports: [
    CommonModule,
    CoreUiModule,
    GatePierLowerEscalatorRoutingModule,
    BoardingSharedModule
  ]
})
export class GatePierLowerEscalatorModule { }