import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: '[app-yah-marker]',
  templateUrl: './yah-marker.component.html',
  styleUrls: ['./yah-marker.component.scss'],
  host: { '[attr.transform]': 'transform' }
})
export class YahMarkerComponent implements OnChanges {

  @Input() labelOffsetX: number = 0;
  @Input() labelOffsetY: number = 0;
  @Input() rotate: number = 0;
  @Input() x: number = 0;
  @Input() y: number = 0;

  beaconTransform: string | undefined;
  labelTransform: string | undefined;
  transform: string | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    const { labelOffsetX, labelOffsetY, rotate, x, y } = this;

    if (changes['x'] || changes['y']) {
      this.transform = `translate(${x}, ${y})`;
    }

    if (changes['rotate']) {
      this.beaconTransform = `rotate(${rotate})`;
    }

    if (changes['labelOffsetX'] || changes['labelOffsetY']) {
      this.labelTransform = `translate(${labelOffsetX}, ${labelOffsetY})`;
    }
  }

}
