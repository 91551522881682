<!-- Background -->
<img class="background layer" src="assets/images/bos-term-e_3840x720.png">

<!-- Background Overlay -->
<div class="background-overlay layer">

  <!-- BOS Logo -->
  <img class="bos-logo" src="assets/logos/boston-logan.svg">

</div>

<!-- Content -->
<div class="content layer">

  <!-- Left Door -->
  <app-boarding-door class="boarding-door boarding-door-left" [door]="leftDoor" [flightDataConfig]="flightDataConfigLeft"></app-boarding-door>

  <!-- Right Door -->
  <app-boarding-door class="boarding-door boarding-door-right" [door]="rightDoor" [flightDataConfig]="flightDataConfigRight"></app-boarding-door>

</div>