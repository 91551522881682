import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreUiModule } from '@aoc/core-ui';
import { ModuleId } from '@aoc/sign';

import { GidsComponent } from './gids.component';
import { GidsConfigService } from './gids-config.service';
import { GidsRoutingModule } from './gids-routing.module';

@ModuleId('GidsModule')
@NgModule({
  declarations: [
    GidsComponent
  ],
  imports: [
    CommonModule,
    CoreUiModule,
    GidsRoutingModule
  ],
  providers: [
    GidsConfigService
  ]
})
export class GidsModule { }
