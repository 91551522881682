import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BoardingPodiumCenterModule } from './boarding-podium-center/boarding-podium-center.module';
import { BoardingPodiumSideModule } from './boarding-podium-side/boarding-podium-side.module';
import { BoardingTotemModule } from './boarding-totem/boarding-totem.module';
import { GatePierModule } from './gate-pier/gate-pier.module';

const routes: Routes = [
  {
    path: 'boarding-podium-center',
    loadChildren: () => BoardingPodiumCenterModule
  },
  {
    path: 'boarding-podium-side',
    loadChildren: () => BoardingPodiumSideModule
  },
  {
    path: 'boarding-totem',
    loadChildren: () => BoardingTotemModule
  },
  {
    path: 'gate-pier',
    loadChildren: () => GatePierModule
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EnhancedBoardingRoutingModule { }
