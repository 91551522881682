<!-- Standard Content (not biometric) -->
<ng-container *ngIf="!boardingStatus?.biometric">

  <!-- Background (default/no flight)-->
  <img class="background layer" src="assets/images/bos-term-e_1080x3840.png">

  <!-- Cityscape Background -->
  <app-destination-background
    *ngIf="flight"
    class="cityscape-background layer"
    [airportCode]="flight.destinationAirportCode"
    [extension]="cityscapeBackgroundExtension"
    [size]="cityscapeBackgroundSize">
  </app-destination-background>

  <!-- Background Overlay -->
  <div class="background-overlay layer"></div>

  <!-- Content -->
  <div class="content layer">

    <!-- Header -->
    <app-header class="header" [gate]="gate"></app-header>

    <!-- Flight -->
    <app-flight
      *ngIf="flight"
      class="flight"
      [airline]="flight.airlineName"
      [airlineCode]="flight.airlineCode"
      [destination]="flight.destinationAirport"
      [flightNumber]="flight.flightNumber">
    </app-flight>

    <!-- Boarding Status -->
    <app-boarding-status
      *ngIf="boardingStatus"
      class="boarding-status"
      [boarding]="boardingStatus.boarding"
      [boardingGroups]="boardingStatus.boardingGroups"
      [busBoarding]="boardingStatus.busBoarding"
      [statusCode]="boardingStatus.statusCode">
    </app-boarding-status>

    <!-- Footer -->
    <app-footer class="footer"></app-footer>

  </div>

</ng-container>

<!-- Biometric Content -->
<ng-container *ngIf="boardingStatus?.biometric">

  <!-- Biometric Slideshow -->
  <aoc-slideshow *ngIf="biometricPlaylist" class="biometric-slideshow layer" [playlist]="biometricPlaylist"></aoc-slideshow>

</ng-container>