import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-destination-airline-logo',
  templateUrl: './destination-airline-logo.component.html',
  styleUrls: ['./destination-airline-logo.component.scss']
})
export class DestinationAirlineLogoComponent {

  @Input() airlineCode: string | undefined;
  @Input() destination: string | undefined;

}
