import { Injectable } from '@angular/core';

import { ConfigService } from '@aoc/config';

import { FidsApiConfig } from './fids-api-config.model';
import { FidsConfig } from './fids-config.model';

@Injectable()
export class FidsConfigService {

  constructor(
    private configService: ConfigService
  ) {}

  async getConfig(): Promise<FidsConfig> {
    const config = await this.configService.get<FidsApiConfig>({});

    return new FidsConfig(config);
  }

}