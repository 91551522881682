import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ModuleId } from '@aoc/sign';

import { AirportShuttleRoutingModule } from './airport-shuttle-routing.module';

@ModuleId('AirportShuttleModule')
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AirportShuttleRoutingModule
  ]
})
export class AirportShuttleModule { }
