import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-page-marker',
  templateUrl: './page-marker.component.html',
  styleUrls: ['./page-marker.component.scss']
})
export class PageMarkerComponent implements OnChanges {

  @Input() currentPage: number = 0;
  @Input() pageCount: number = 0;

  markers: number[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pageCount']) {
      this.markers = this.getMarkers(this.pageCount);
    }
  }

  private getMarkers(pageCount: number): number[] {
    return Array.from(Array(pageCount).keys());
  }

}
