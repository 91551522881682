import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ModuleId } from '@aoc/sign';

import { EnhancedBoardingRoutingModule } from './enhanced-boarding-routing.module';

@ModuleId('EnhancedBoardingModule')
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EnhancedBoardingRoutingModule
  ]
})
export class EnhancedBoardingModule { }
