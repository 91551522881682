import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: { '[class]': 'layout' }
})
export class HeaderComponent {

  @Input() airline: string | undefined;
  @Input() airlineCode: string | undefined;
  @Input() destination: string | undefined;
  @Input() flightNumber: number | undefined;
  @Input() layout: 'short' | 'long' = 'long';

}
