import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subject, takeUntil, timer } from 'rxjs';

import { CodeShare } from 'src/app/shared/models/code-share.model';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-flight',
  templateUrl: './flight.component.html',
  styleUrls: ['./flight.component.scss']
})
export class FlightComponent implements OnChanges, OnDestroy, OnInit {

  @Input() airline: string | undefined;
  @Input() airlineCode: string | undefined;
  @Input() codeShares: CodeShare[] = [];
  @Input() destination: string | undefined;
  @Input() flightNumber: number | undefined;
  @Input() scheduledTime: string | undefined;
  @Input() status: string | undefined;

  currentCodeShare: CodeShare | undefined;

  private codeShareRotateCounter: number = 0;
  private codeShareRotateIntervalMs: number = environment.codeShareRotateIntervalMs || 15000;
  private unsubscribe: Subject<any> = new Subject();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['codeShares']) {
      const { codeShares, codeShareRotateCounter } = this;

      this.rotateCodeShare(codeShares, codeShareRotateCounter);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
  }

  ngOnInit(): void {
    this.startCodeShareRotation();
  }

  private rotateCodeShare(codeShares: CodeShare[], rotateCounter: number): void {
    codeShares = codeShares || [];

    const index = rotateCounter % codeShares.length;

    this.codeShareRotateCounter = rotateCounter;
    this.currentCodeShare = codeShares[index];
  }

  private startCodeShareRotation(): void {
    const delay = this.codeShareRotateIntervalMs;

    timer(0, delay)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(counter => this.rotateCodeShare(this.codeShares, counter));
  }

}
