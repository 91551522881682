<!-- BOS Logo -->
<img class="bos-logo" src="assets/logos/boston-logan.svg">

<!-- Next Flight -->
<div *ngIf="nextFlight" class="next-flight">

  <!-- Label -->
  <span class="next-flight-label">
    Next Flight:
  </span>

  <!-- Destination -->
  <span class="destination">
    {{ nextFlight.destinationAirport }}
  </span>

  <!-- Airline -->
  <span class="airline">
    {{ nextFlight.airlineName }}
  </span>

  <!-- Flight Number -->
  <span class="flight-number">
    {{ nextFlight.flightNumber }}
  </span>

  <!-- Scheduled Time -->
  <span class="scheduled-time">

    <!-- Hour & Minute -->
    <span class="hour-minute">
      {{ nextFlight.scheduledTime | date:'h:mm' }}
    </span>

    <!-- AM/PM -->
    <span class="am-pm">
      {{ nextFlight.scheduledTime | date:'a' }}
    </span>

  </span>

</div>

<!-- Time -->
<div class="time">

  <!-- Time Label -->
  <span class="time-label">
    Current Time
  </span>

  <!-- Hour & Minute -->
  <aoc-now class="hour-minute" format="h:mm"></aoc-now>

  <!-- AM/PM -->
  <aoc-now class="am-pm" format="a"></aoc-now>

</div>