import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subject, takeUntil, tap, timer } from 'rxjs';

import { Playlist } from '@aoc/data-models';

import { CurbsideSignPage } from './curbside-sign-page.model';
import { CheckInPosition } from 'src/app/shared/models/check-in-position.model';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-curbside-sign',
  templateUrl: './curbside-sign.component.html',
  styleUrls: ['./curbside-sign.component.scss'],
  animations: [
    trigger('pageChange', [
      state('true', style({
        transform: 'translateY(0)'
      })),
      state('false', style({
        transform: 'translateY(100%)'
      })),
      transition('false => true', [
        style({ transform: 'translateY(-100%)' }),
        animate('1s ease-in-out')
      ]),
      transition('true => false', [
        animate('1s ease-in-out')
      ])
    ])
  ]
})
export class CurbsideSignComponent implements OnChanges, OnDestroy {

  @Input() backgroundPlaylist: Playlist | undefined;
  @Input() noDataPlaylist: Playlist | undefined;
  @Input() openCounters: CheckInPosition[] = [];
  @Input() pageChangeCounter = 0;

  currentPageIndex: number = 0;
  pages: CurbsideSignPage[] = [];
  showNoDataSlideshow: boolean = false;

  private pageChangeIntervalSeconds: number = environment.curbsidePageChangeIntervalSeconds || 10;
  private unsubscribe: Subject<void> = new Subject();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['openCounters']) {
      const { openCounters } = this;

      const pages = this.getPages(openCounters);

      this.currentPageIndex = this.getCurrentPageIndex(pages, this.pageChangeCounter);
      this.pages = pages;
      this.showNoDataSlideshow = openCounters.length === 0;
    }

    if (changes['pageChangeCounter']) {
      this.currentPageIndex = this.getCurrentPageIndex(this.pages, this.pageChangeCounter);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
  }

  private getCurrentPageIndex(pages: CurbsideSignPage[], pageChangeCounter: number): number {
    return pageChangeCounter % pages.length;
  }

  private getPages(openCounters: CheckInPosition[]): CurbsideSignPage[] {
    const countersPerPage = 3;

    const pages = [];

    for (let i = 0; i < openCounters.length; i += countersPerPage) {
      const counters = openCounters.slice(i, i + countersPerPage);

      const page = { counters };

      pages.push(page);
    }

    return pages;
  }

}
