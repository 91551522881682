<!-- Bracket Icon -->
<img class="bracket-icon" src="assets/icons/boarding-bracket.svg">

<!-- Message Container -->
<div class="message-container">

  <!-- Message -->
  <div class="message">
    Last Call. All Groups.
  </div>

</div>