import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { ConfigService } from '@aoc/config';
import { Flight, Playlist } from '@aoc/data-models';
import { FlightDataConfig, FlightDataService } from '@aoc/flight-data';
import { SignComponent } from '@aoc/sign';

import { Boarding } from './boarding.model';
import { BoardingInfo } from '../shared/models/boarding-info.model';
import { BoardingInfoService } from '../shared/services/boarding-info.service';

@Component({
  template: ''
})
export class BoardingBaseComponent extends SignComponent implements OnDestroy, OnInit {

  boardingInfo: BoardingInfo | undefined;
  flight: Flight | undefined;
  noFlightPlaylist: Playlist | undefined;

  private unsubscribe: Subject<any> = new Subject();

  constructor(
    private boardingInfoService: BoardingInfoService,
    private configService: ConfigService,
    private flightDataService: FlightDataService
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
  }

  async ngOnInit(): Promise<any> {
    const config = await this.configService.get<Boarding>({}, this.id);

    const flightDataConfig = new FlightDataConfig({ configName: config.flightData.name });
    const gate = config.gate.slice(1);

    this.noFlightPlaylist = config.noFlightPlaylist;

    this.flightDataService.initConfig(flightDataConfig);

    this.flightDataService.getFlights()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(flights => this.flight = flights[0]);

    this.boardingInfoService.getBoardingInfo(gate)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(boardingInfo => this.boardingInfo = boardingInfo);
  }

}