<!-- Airline -->
<div class="col col-airline">
  Airline
</div>

<!-- Domestic Terminal -->
<div class="col col-terminal">
  Domestic Terminal
</div>

<!-- International Arrivals -->
<div class="col col-terminal">
  International Arrivals
</div>