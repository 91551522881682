import { Component, OnInit } from '@angular/core';

import { ConfigService } from '@aoc/config';
import { Playlist } from '@aoc/data-models';
import { FlightDataConfig } from '@aoc/flight-data';
import { ComponentId, SignComponent } from '@aoc/sign';

import { Boarding } from '../boarding.model';
import { BoardingDoor } from 'src/app/shared/enums/boarding-door.enum';

@ComponentId('BoardingModule.BoardingDoorsModule.BoardingDoorsComponent')
@Component({
  selector: 'app-boarding-doors',
  templateUrl: './boarding-doors.component.html',
  styleUrls: ['./boarding-doors.component.scss']
})
export class BoardingDoorsComponent extends SignComponent implements OnInit {

  flightDataConfig: FlightDataConfig = new FlightDataConfig();
  leftDoor: BoardingDoor = BoardingDoor.Left;
  noFlightPlaylist: Playlist | undefined;
  rightDoor: BoardingDoor = BoardingDoor.Right;

  constructor(
    private configService: ConfigService
  ) {
    super();
  }

  async ngOnInit(): Promise<any> {
    const config = await this.configService.get<Boarding>({}, this.id);

    this.flightDataConfig = new FlightDataConfig({ configName: config.flightData.name });
    this.noFlightPlaylist = config.noFlightPlaylist;
  }

}
