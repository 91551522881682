import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription, takeUntil } from 'rxjs';

import { ConfigService } from '@aoc/config';
import { Flight, Playlist } from '@aoc/data-models';
import { FlightDataConfig, FlightDataService } from '@aoc/flight-data';
import { ComponentId, SignComponent } from '@aoc/sign';

import { BoardingTotem } from './boarding-totem.model';
import { DestinationBackgroundExtension } from 'src/app/shared/enums/destination-background-extension.enum';
import { DestinationBackgroundSize } from 'src/app/shared/enums/destination-background-size.enum';
import { BoardingStatus } from 'src/app/shared/models/boarding-status.model';
import { BoardingService } from 'src/app/shared/services/boarding.service';

@ComponentId('EnhancedBoardingModule.BoardingTotemModule.BoardingTotemComponent')
@Component({
  selector: 'app-boarding-totem',
  templateUrl: './boarding-totem.component.html',
  styleUrls: ['./boarding-totem.component.scss']
})
export class BoardingTotemComponent extends SignComponent implements OnDestroy, OnInit {

  biometricPlaylist: Playlist | undefined;
  boardingStatus: BoardingStatus | undefined;
  boardingStatusSubscription: Subscription | undefined;
  cityscapeBackgroundExtension: DestinationBackgroundExtension = DestinationBackgroundExtension.PNG;
  cityscapeBackgroundSize: DestinationBackgroundSize = DestinationBackgroundSize.Standard;
  defaultGate: string | undefined;
  flight: Flight | undefined;
  gate: string | undefined;

  private unsubscribe: Subject<any> = new Subject();

  constructor(
      private boardingService: BoardingService,
      private configService: ConfigService,
      private flightDataService: FlightDataService
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
  }

  async ngOnInit(): Promise<any> {
    const config = await this.configService.get<BoardingTotem>({}, this.id);

    const flightDataConfig = new FlightDataConfig({ configName: config.flightData.name });

    this.biometricPlaylist = config.biometricPlaylist;
    this.defaultGate = config.gate;
    this.gate = config.gate;

    this.flightDataService.initConfig(flightDataConfig);

    this.flightDataService.getFlights()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(flights => this.onFlightsChange(flights));
  }

  private getBoardingStatus(gate: string, flightId: string): void {
    this.boardingStatusSubscription?.unsubscribe();

    const subscription = this.boardingService.getBoardingStatus(gate, flightId)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(boardingStatus => this.boardingStatus = boardingStatus);

    this.boardingStatusSubscription = subscription;
  }

  private onFlightsChange(flights: Flight[]): void {
    const flight = flights[0];

    const gate = flight ? flight.gate : this.defaultGate;

    this.flight = flight;
    this.gate = gate;

    if (flight && flight.flightHistoryId != undefined) {
      this.getBoardingStatus(flight.gate, flight.flightHistoryId);
    } else {
      this.resetBoardingStatus();
    }
  }

  private resetBoardingStatus(): void {
    this.boardingStatusSubscription?.unsubscribe();

    this.boardingStatus = undefined;
  }

}
