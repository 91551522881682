<!-- Title -->
<div
  class="title font-bos-title"
  [class.one-char]="title?.length === 1"
  [class.two-char]="title?.length === 2">
  {{ title }}
</div>

<!-- Terminal -->
<div *ngIf="terminal" class="terminal">

  <!-- Label -->
  <div class="label">
    Terminal
  </div>

  <!-- Terminal Logo -->
  <app-terminal-logo class="terminal-logo" [terminal]="terminal"></app-terminal-logo>

</div>

<!-- BOS Logo -->
<app-logo class="bos-logo" name="boston-logan"></app-logo>