import { Pipe, PipeTransform } from '@angular/core';

import { gateNumberFromGate } from '../functions/gate-number-from-gate.function';

@Pipe({ name: 'gateNumberFromGate' })
export class GateNumberFromGatePipe implements PipeTransform {

  transform(gate: string | undefined): string | undefined {
    return gate && gateNumberFromGate(gate);
  }

}