<!-- Content (actively boarding departure) -->
<div *ngIf="boarding" class="content">

  <!-- Flight -->
  <app-flight
    *ngIf="departure"
    class="flight"
    [airline]="departure.airlineName"
    [airlineCode]="departure.airlineCode"
    [destination]="departure.destinationAirport"
    [flightNumber]="departure.flightNumber">
  </app-flight>

  <!-- Boarding Status -->
  <div class="boarding-status">
    Boarding
  </div>

  <!-- Footer -->
  <footer></footer>

</div>

<!-- Slideshow (no actively boarding departure) -->
<aoc-slideshow *ngIf="!boarding && playlist" class="slideshow" [playlist]="playlist"></aoc-slideshow>