import { Component } from '@angular/core';

import { ComponentId } from '@aoc/sign';

import { GidsBaseComponent } from '../gids-base.component';
import { DestinationBackgroundSize } from 'src/app/shared/enums/destination-background-size.enum';

@ComponentId('GidsModule.BoardingModule.BoardingComponent')
@Component({
  selector: 'app-boarding',
  templateUrl: './boarding.component.html',
  styleUrls: ['./boarding.component.scss']
})
export class BoardingComponent extends GidsBaseComponent {

  override enableBoardingStatus: boolean = true;

  destinationBackgroundSize: DestinationBackgroundSize = DestinationBackgroundSize.Standard;

}
