import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';

import { WalkTime } from '../models/walk-time.model';

import { environment } from 'src/environments/environment';

@Injectable()
export class WalkTimeService {

  constructor(
    private http: HttpClient
  ) {}

  getWalkTime(locationName: string | undefined, gate: string | undefined): Observable<WalkTime | undefined> {
    return this.getWalkTimes(locationName).pipe(
      map(walkTimes => walkTimes.find(walkTime => walkTime.gate === gate))
    );
  }

  getWalkTimes(locationName: string | undefined): Observable<WalkTime[]> {
    const baseUrl = environment.walkTimesBaseUrl;
    const url = `${baseUrl}/${locationName}.json`;

    return this.http.get<WalkTime[]>(url).pipe(
      catchError(error => of([]))
    );
  }

}