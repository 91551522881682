<!-- Flight List -->
<aoc-paged-flight-list
  class="flight-list"
  [flightDataConfig]="flightDataConfig"
  [maxPages]="maxPages"
  [pageChangeIntervalMs]="pageChangeIntervalMs"
  [pageSize]="pageSize"
  [sortBy]="sortBy">
  <ng-template let-page>

    <!-- Page -->
    <div class="page" [@pageChange]="page.current">

      <!-- Flight -->
      <app-flight-list-row
        *ngFor="let flight of page.flights; trackBy: trackFlightById"
        class="flight-list-row"
        [airline]="flight.airlineName"
        [airlineCode]="flight.airlineCode"
        [claim]="flight.baggage"
        [destination]="flight.destinationAirport"
        [flightNumber]="flight.flightNumber"
        [flightType]="flightType"
        [gate]="flight.gate"
        [origin]="flight.originAirport"
        [status]="flight.remarksWithTime"
        [statusCode]="flight.flightStatus"
        [terminal]="flight.terminal"
        [time]="flight.scheduledTime"
        [walkTimes]="walkTimes">
      </app-flight-list-row>

    </div>

  </ng-template>
</aoc-paged-flight-list>