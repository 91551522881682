<!-- Throbber -->
<svg:g
  class="throbber"
  stroke="#be2525"
  [animationDurationMs]="3000"
  [endRadius]="80"
  [spawnFrequencyMs]="300"
  [startRadius]="18"
  [startStrokeWidth]="5"
  [x]="0"
  [y]="0"
  aoc-throbber>
</svg:g>

<!-- Beacon -->
<svg:g class="beacon" [attr.transform]="beaconTransform">
  <svg:circle class="ring" r="21.167"/>
  <svg:path class="arrow" d="M142.378,108.16l-7.882-7.206-7.882,7.206,7.882-24.32Z" transform="translate(-134.5 -97)"/>
</svg:g>

<!-- Label -->
<svg:g class="label" [attr.transform]="labelTransform">
  <svg:text>You Are</svg:text>
  <svg:text transform="translate(15, 23)">Here</svg:text>
</svg:g>