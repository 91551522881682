import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, Subscription, map, takeUntil } from 'rxjs';

import { ConfigService } from '@aoc/config';
import { Flight, Playlist } from '@aoc/data-models';
import { FlightDataConfig, FlightDataService, SocketService } from '@aoc/flight-data';
import { LogService } from '@aoc/log';
import { SignComponent } from '@aoc/sign';

import { GatePier } from './gate-pier.model';
import { BoardingStatusCode } from 'src/app/shared/enums/boarding-status-code.enum';
import { BoardingStatus } from 'src/app/shared/models/boarding-status.model';
import { BoardingService } from 'src/app/shared/services/boarding.service';

@Component({
  template: ''
})
export class GatePierBaseComponent extends SignComponent implements OnDestroy, OnInit {

  arrival: Flight | undefined;
  boarding: boolean = false;
  departure: Flight | undefined;
  playlist: Playlist | undefined;

  private arrivalDomesticPlaylist: Playlist | undefined;
  private arrivalPassportControlPlaylist: Playlist | undefined;
  private arrivalsService: FlightDataService;
  private boardingStatusSubscription: Subscription | undefined;
  private defaultPlaylist: Playlist | undefined;
  private departurePlaylist: Playlist | undefined;
  private departuresService: FlightDataService;
  private unsubscribe: Subject<any> = new Subject();

  constructor(
      flightDataConfig: FlightDataConfig,
      httpClient: HttpClient,
      logService: LogService,
      private boardingService: BoardingService,
      private configService: ConfigService
  ) {
    super();

    this.arrivalsService = this.createFlightDataService(flightDataConfig, httpClient, logService);
    this.departuresService = this.createFlightDataService(flightDataConfig, httpClient, logService);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
  }

  async ngOnInit(): Promise<any> {
    const config = await this.configService.get<GatePier>({}, this.id);

    this.arrivalDomesticPlaylist = config.arrivalDomesticPlaylist;
    this.arrivalPassportControlPlaylist = config.arrivalPassportControlPlaylist;
    this.defaultPlaylist = config.defaultPlaylist;
    this.departurePlaylist = config.departurePlaylist;
    this.playlist = config.defaultPlaylist;

    this.getFlight(config.arrivalFlightData.name, this.arrivalsService)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(flight => this.onArrivalChange(flight));

    this.getFlight(config.departureFlightData.name, this.departuresService)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(flight => this.onDepartureChange(flight));
  }

  protected onArrivalChange(flight: Flight | undefined): void {
    this.arrival = flight;
    this.playlist = this.getPlaylist(flight, this.boarding);
  }

  protected onBoardingStatusChange(boardingStatus: BoardingStatus | undefined): void {
    const boarding = boardingStatus ? this.isBoarding(boardingStatus) : false;

    this.boarding = boarding;
    this.playlist = this.getPlaylist(this.arrival, boarding);
  }

  protected onDepartureChange(flight: Flight | undefined): void {
    const gate = flight?.gate;
    const flightId = flight?.flightHistoryId;

    if (gate && flightId) {
      this.getBoardingStatus(gate, flightId);
    } else {
      this.resetBoardingStatus();
    }

    this.departure = flight;
  }

  protected resetBoardingStatus(): void {
    this.boardingStatusSubscription?.unsubscribe();

    this.boarding = false;
    this.playlist = this.getPlaylist(this.arrival, false);
  }

  private createFlightDataService(config: FlightDataConfig, http: HttpClient, log: LogService): FlightDataService {
    const socketService = new SocketService(config);

    return new FlightDataService(config, http, socketService, log);
  }

  private getBoardingStatus(gate: string, flightId: string): void {
    this.boardingStatusSubscription?.unsubscribe();

    const subscription = this.boardingService.getBoardingStatus(gate, flightId)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(boardingStatus => this.onBoardingStatusChange(boardingStatus));

    this.boardingStatusSubscription = subscription;
  }

  private getFlight(configName: string, flightDataService: FlightDataService): Observable<Flight> {
    const config = new FlightDataConfig({ configName });

    flightDataService.initConfig(config);

    return flightDataService.getFlights().pipe(
        map(flights => flights[0])
    );
  }

  private getPlaylist(arrival: any, boarding: boolean): Playlist | undefined {
    const { arrivalDomesticPlaylist, arrivalPassportControlPlaylist, defaultPlaylist, departurePlaylist } = this;

    let playlist = defaultPlaylist;

    if (arrival) {
      const { customsRequired } = arrival;

      playlist = customsRequired ? arrivalPassportControlPlaylist : arrivalDomesticPlaylist;
    }

    if (boarding) {
      playlist = departurePlaylist;
    }

    return playlist;
  }

  private isBoarding(boardingStatus: BoardingStatus): boolean {
    const { boardingGroups, statusCode } = boardingStatus;

    let boarding = false;

    if (statusCode !== BoardingStatusCode.Closed) {
      boarding = boardingGroups.some(group => group.boarding);
    }

    return boarding;
  }

}
