import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'video-bar',
    loadChildren: () => import('./video-bar/video-bar.module').then(m => m.VideoBarModule)
  },
  {
    path: 'video-wall',
    loadChildren: () => import('./video-wall/video-wall.module').then(m => m.VideoWallModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BidsRoutingModule { }
