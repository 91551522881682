import { Component, OnInit } from '@angular/core';

import { ConfigService } from '@aoc/config';
import { FlightDataConfig } from '@aoc/flight-data';
import { ComponentId, SignComponent } from '@aoc/sign';

import { BoardingPodiumCenter } from './boarding-podium-center.model';
import { BoardingDoor } from 'src/app/shared/enums/boarding-door.enum';

@ComponentId('EnhancedBoardingModule.BoardingPodiumCenterModule.BoardingPodiumCenterComponent')
@Component({
  selector: 'app-boarding-podium-center',
  templateUrl: './boarding-podium-center.component.html',
  styleUrls: ['./boarding-podium-center.component.scss']
})
export class BoardingPodiumCenterComponent extends SignComponent implements OnInit {

  flightDataConfigLeft: FlightDataConfig = new FlightDataConfig();
  flightDataConfigRight: FlightDataConfig = new FlightDataConfig();
  leftDoor: BoardingDoor = BoardingDoor.Left;
  rightDoor: BoardingDoor = BoardingDoor.Right;

  constructor(
    private configService: ConfigService
  ) {
    super();
  }

  async ngOnInit(): Promise<any> {
    const config = await this.configService.get<BoardingPodiumCenter>({}, this.id);

    this.flightDataConfigLeft = new FlightDataConfig({ configName: config.flightDataLeft.name });
    this.flightDataConfigRight = new FlightDataConfig({ configName: config.flightDataRight.name });
  }

}
