import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-row',
  templateUrl: './row.component.html',
  styleUrls: ['./row.component.scss'],
  host: { 'class': 'row' }
})
export class RowComponent implements OnChanges {

  @Input() airline: string | undefined;
  @Input() airlineCode: string | undefined;
  @Input() counterEnd: number | undefined;
  @Input() counterStart: number | undefined;

  counters: string | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['counterStart'] || changes['counterEnd']) {
      const counterStart = this.counterStart || 0;
      const counterEnd = this.counterEnd || 0;

      this.counters = this.getCounters(counterStart, counterEnd);
    }
  }

  private getCounters(start: number, end: number): string {
    const counters = [];

    if (start) {
      counters.push(start);
    }

    if (end && end !== start) {
      counters.push(end);
    }

    return counters.join(' - ');
  }

}
