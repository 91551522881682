import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ConfigModule, ConfigOptions } from '@aoc/config';
import { DmsDataConfig, DmsDataModule } from '@aoc/dms-data';
import { FlightDataConfig, FlightDataModule } from '@aoc/flight-data';
import { LogConfig, LogModule } from '@aoc/log';
import { SignModule } from '@aoc/sign';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TransitPredictionAdapter } from './shared/adapters/transit-prediction.adapter';
import { BoardingService } from './shared/services/boarding.service';
import { BoardingInfoService } from './shared/services/boarding-info.service';
import { CheckInCounterService } from './shared/services/check-in-counter.service';
import { RccAirlinesService } from './shared/services/rcc-airlines.service';
import { TransitTimesService } from './shared/services/transit-times.service';
import { WalkTimeService } from './shared/services/walk-time.service';

import { environment } from 'src/environments/environment';
import { VersionComponent } from './version/version.component';

const configOptions = new ConfigOptions(environment.configOptions);
const dmsDataConfig = new DmsDataConfig(environment.dmsDataConfig);
const flightDataConfig = new FlightDataConfig(environment.flightDataConfig);
const logConfig = new LogConfig(environment.logConfig);

@NgModule({
  declarations: [
    AppComponent,
    VersionComponent
  ],
  imports: [
    BrowserAnimationsModule,
    ConfigModule.forRoot(environment, configOptions),
    DmsDataModule.forRoot(dmsDataConfig),
    FlightDataModule.forRoot(flightDataConfig),
    LogModule.forRoot(logConfig),
    SignModule.forRoot(environment, configOptions),
    AppRoutingModule
  ],
  providers: [
    BoardingService,
    BoardingInfoService,
    CheckInCounterService,
    RccAirlinesService,
    TransitPredictionAdapter,
    TransitTimesService,
    WalkTimeService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
