import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SlideshowSignComponent, ExternalSignComponent, MessagingModule, SignShowSignComponent } from '@aoc/sign';

import { BoardingModule } from './boarding/boarding.module';
import { BidsModule } from './bids/bids.module';
import { FidsModule } from './fids/fids.module';
import { GidsModule } from './gids/gids.module';
import { AirportShuttleModule } from './airport-shuttle/airport-shuttle.module';
import { CheckInCounterDirectoryModule } from './check-in-counter-directory/check-in-counter-directory.module';
import { CurbsideModule } from './curbside/curbside.module';
import { EnhancedBoardingModule } from './enhanced-boarding/enhanced-boarding.module';
import { RccAirlinesModule } from './rcc-airlines/rcc-airlines.module';
import { YahSlideshowModule } from './yah-slideshow/yah-slideshow.module';
import { VersionComponent } from './version/version.component';

const routes: any[] = [
  { path: '', component: VersionComponent },
  {
    path: 'airport-shuttle',
    loadChildren: () => AirportShuttleModule
  },
  {
    path: 'bids',
    loadChildren: () => BidsModule
  },
  {
    path: 'boarding',
    loadChildren: () => BoardingModule
  },
  {
    path: 'check-in-counter-directory',
    loadChildren: () => CheckInCounterDirectoryModule
  },
  {
    path: 'curbside',
    loadChildren: () => CurbsideModule
  },
  {
    path: 'enhanced-boarding',
    loadChildren: () => EnhancedBoardingModule
  },
  {
    path: 'external/:id',
    component: ExternalSignComponent
  },
  {
    path: 'fids',
    loadChildren: () => FidsModule
  },
  {
    path: 'gids',
    loadChildren: () => GidsModule
  },
  {
    path: 'messaging',
    loadChildren: () => MessagingModule
  },
  {
    path: 'rcc-airlines',
    loadChildren: () => RccAirlinesModule
  },
  {
    path: 'sign-show/standard/:id',
    component: SignShowSignComponent
  },
  {
    path: 'slideshow/:id',
    component: SlideshowSignComponent
  },
  {
    path: 'yah-slideshow',
    loadChildren: () => YahSlideshowModule
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

