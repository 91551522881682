<!-- Background (default/no flight) -->
<img class="background layer" src="assets/images/bos-term-e_3840x1080.png">

<!-- Cityscape Background -->
<app-destination-background
  *ngIf="flight"
  class="cityscape-background layer"
  [airportCode]="flight.destinationAirportCode"
  [extension]="cityscapeBackgroundExtension"
  [size]="cityscapeBackgroundSize">
</app-destination-background>

<!-- Background Overlay -->
<div class="background-overlay layer">

  <!-- BOS Logo (no flight) -->
  <img *ngIf="!flight" class="bos-logo" src="assets/logos/boston-logan.svg">

</div>

<!-- Content -->
<div class="content layer">

  <!-- Header -->
  <header>

    <!-- Gate -->
    <app-gate class="gate" [gate]="gate"></app-gate>

    <!-- Flight -->
    <app-flight
      *ngIf="flight"
      class="flight"
      [airline]="flight.airlineName"
      [airlineCode]="flight.airlineCode"
      [codeShares]="flight.codeShares"
      [destination]="flight.destinationAirport"
      [flightNumber]="flight.flightNumber"
      [scheduledTime]="flight.scheduledTime"
      [status]="flight.remarksWithTime">
    </app-flight>

  </header>

  <!-- Boarding Info -->
  <div *ngIf="boardingStatus" class="boarding-info">

    <!-- Boarding Status -->
    <app-boarding-status *ngIf="boardingStatus.boarding" class="boarding-status" [biometric]="boardingStatus.biometric" [busBoarding]="boardingStatus.busBoarding"></app-boarding-status>

    <!-- Boarding Messages -->
    <ng-container [ngSwitch]="boardingStatus.statusCode">

      <!-- Boarding Groups -->
      <app-boarding-groups *ngSwitchCase="'Open'" class="boarding-groups" [boardingGroups]="boardingStatus.boardingGroups"></app-boarding-groups>
      <app-boarding-groups *ngSwitchCase="'Boarding'" class="boarding-groups" [boardingGroups]="boardingStatus.boardingGroups"></app-boarding-groups>

      <!-- Last Call -->
      <app-last-call *ngSwitchCase="'LastCall'" class="last-call"></app-last-call>

      <!-- Flight Closed -->
      <app-flight-closed *ngSwitchCase="'Closed'" class="flight-closed"></app-flight-closed>

    </ng-container>

  </div>

  <!-- Footer -->
  <app-footer class="footer" [nextFlight]="nextFlight"></app-footer>

</div>