import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';

import { FlightTypeEnum } from '@aoc/data-models';

import { environment } from 'src/environments/environment';

@Directive()
export class FlightListRowBaseComponent implements OnChanges {

  @Input() airline: string | undefined;
  @Input() airlineCode: string | undefined;
  @Input() claim: string | undefined;
  @Input() destination: string | undefined;
  @Input() flightNumber: string | undefined;
  @Input() flightType: FlightTypeEnum | undefined;
  @Input() gate: string | undefined;
  @Input() origin: string | undefined;
  @Input() status: string | undefined;
  @Input() statusCode: string | undefined;
  @Input() terminal: string | undefined;
  @Input() time: string | undefined;

  airlineLogoUrl: string | undefined;
  showAirlineLogo: boolean = false;

  protected classList: string[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    const { airlineCode, flightType, statusCode, terminal } = changes;

    if (airlineCode) {
      this.airlineLogoUrl = this.getAirlineLogoUrl(airlineCode.currentValue);
      this.showAirlineLogo = !!airlineCode.currentValue;
    }

    if (flightType || statusCode || terminal) {
      this.classList = this.getClassList();
    }
  }

  hideAirlineLogo(): void {
    this.showAirlineLogo = false;
  }

  private getAirlineLogoUrl(airlineCode: string | undefined): string {
    const baseUrl = environment.airlineLogoBaseUrl;

    return `${baseUrl}/${airlineCode}.svg`;
  }

  private getClassList(): string[] {
    const { flightType, statusCode, terminal } = this;

    let classList = [];

    if (flightType) {
      classList.push(flightType);
    }

    if (statusCode) {
      classList.push(`status-${statusCode}`);
    }

    if (terminal) {
      classList.push(`term-${terminal.toLowerCase()}`);
    }

    return classList;
  }

}