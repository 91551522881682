import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-gate',
  templateUrl: './gate.component.html',
  styleUrls: ['./gate.component.scss'],
  host: { '[class]': 'terminalClass' }
})
export class GateComponent implements OnChanges {

  @Input() gate: string | undefined;
  @Input() terminal: string | undefined;

  terminalClass: string | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['terminal']) {
      const { terminal } = this;

      this.terminalClass = terminal && `term-${ terminal }`;
    }
  }

}
