import { Component } from '@angular/core';

import { ComponentId } from '@aoc/sign';

import { BoardingBaseComponent } from '../boarding-base.component';
import { BoardingDoor } from 'src/app/shared/enums/boarding-door.enum';

@ComponentId('BoardingModule.BehindBoardingDoorsModule.BehindBoardingDoorsComponent')
@Component({
  selector: 'app-behind-boarding-doors',
  templateUrl: './behind-boarding-doors.component.html',
  styleUrls: ['./behind-boarding-doors.component.scss']
})
export class BehindBoardingDoorsComponent extends BoardingBaseComponent {

  door: BoardingDoor = BoardingDoor.Right;

}
