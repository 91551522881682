import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { gateNumberFromGate } from 'src/app/shared/functions/gate-number-from-gate.function';
import { terminalFromGate } from 'src/app/shared/functions/terminal-from-gate.function';

@Component({
  selector: 'app-gate',
  templateUrl: './gate.component.html',
  styleUrls: ['./gate.component.scss']
})
export class GateComponent implements OnChanges {

  @Input() gate: string | undefined;

  gateNumber: string | undefined;
  terminal: string | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['gate']) {
      const { gate } = this;

      this.gateNumber = gate && gateNumberFromGate(gate);
      this.terminal = gate && terminalFromGate(gate);
    }
  }

}
