<!-- Airline -->
<div class="col col-airline">

  <!-- Airline Logo -->
  <app-airline-logo class="airline-logo" [airlineCode]="airlineCode"></app-airline-logo>

  <!-- Airline Name -->
  <div class="airline-name">
    {{ airlineName }}
  </div>

</div>

<!-- Domestic Terminal -->
<div class="col col-terminal">
  <app-terminal-logo class="terminal-logo" [terminal]="domesticTerminal"></app-terminal-logo>
</div>

<!-- International Arrivals -->
<div class="col col-terminal">
  <app-terminal-logo class="terminal-logo" [terminal]="internationalTerminal"></app-terminal-logo>
</div>