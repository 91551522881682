import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreUiModule } from '@aoc/core-ui';
import { ModuleId } from '@aoc/sign';

import { BoardingDoorsComponent } from './boarding-doors.component';
import { BoardingDoorsRoutingModule } from './boarding-doors-routing.module';
import { BoardingDoorComponent } from './boarding-door/boarding-door.component';
import { SharedModule } from 'src/app/shared/shared.module';

@ModuleId('BoardingModule.BoardingDoorsModule')
@NgModule({
  declarations: [
    BoardingDoorsComponent,
    BoardingDoorComponent
  ],
  imports: [
    CommonModule,
    CoreUiModule,
    BoardingDoorsRoutingModule,
    SharedModule
  ]
})
export class BoardingDoorsModule { }