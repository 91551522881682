import { Component } from '@angular/core';

import { ComponentId } from '@aoc/sign';

import { GatePierBaseComponent } from '../gate-pier-base.component';

@ComponentId('EnhancedBoardingModule.GatePierModule.StandardModule.StandardComponent')
@Component({
  selector: 'app-standard',
  templateUrl: './standard.component.html',
  styleUrls: ['./standard.component.scss']
})
export class StandardComponent extends GatePierBaseComponent {
}
