import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreUiModule } from '@aoc/core-ui';
import { ModuleId } from '@aoc/sign';

import { CurbsideComponent } from './curbside.component';
import { CurbsideRoutingModule } from './curbside-routing.module';
import { CurbsideSignComponent } from './curbside-sign/curbside-sign.component';
import { SharedModule } from '../shared/shared.module';

@ModuleId('CurbsideModule')
@NgModule({
  declarations: [
    CurbsideComponent,
    CurbsideSignComponent
  ],
  imports: [
    CommonModule,
    CoreUiModule,
    CurbsideRoutingModule,
    SharedModule
  ]
})
export class CurbsideModule { }
