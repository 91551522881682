import { FlightDataConfig } from '@aoc/flight-data';

import { GidsApiConfig } from './gids-api-config.model';

export class GidsConfig {

  flightDataConfig: FlightDataConfig;
  gate: string;
  terminal: string;

  constructor(config: GidsApiConfig) {
    const { flightData } = config;

    const flightDataConfigName = flightData && flightData.name;

    this.flightDataConfig = new FlightDataConfig({ configName: flightDataConfigName });
    this.gate = config.gate;
    this.terminal = config.terminal;
  }

}