<!-- Background Slideshow -->
<aoc-slideshow *ngIf="backgroundPlaylist" class="background-slideshow" [playlist]="backgroundPlaylist"></aoc-slideshow>

<!-- Page -->
<div *ngFor="let page of pages; let pageIndex = index" class="page" [@pageChange]="pageIndex === currentPageIndex">

  <!-- Counter -->
  <div *ngFor="let counter of page.counters" class="counter">

    <!-- Airline Logo -->
    <app-airline-logo class="airline-logo" [airlineCode]="counter.AirlineId"></app-airline-logo>

    <!-- Airline Name -->
    <span class="airline-name">{{ counter.AirlineName }}</span>

  </div>

</div>

<!-- No Data Slideshow -->
<aoc-slideshow *ngIf="showNoDataSlideshow && noDataPlaylist" class="no-data-slideshow" [playlist]="noDataPlaylist"></aoc-slideshow>