import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-terminal-logo',
  templateUrl: './terminal-logo.component.html',
  styleUrls: ['./terminal-logo.component.scss']
})
export class TerminalLogoComponent implements OnChanges {

  @Input() terminal: string | undefined;

  showTerminalLogo: boolean = false;
  terminalLogoUrl: string | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    const terminal = changes['terminal']?.currentValue;

    if (changes['terminal']) {
      this.showTerminalLogo = !!terminal;
      this.terminalLogoUrl = this.getTerminalLogoUrl(terminal);
    }
  }

  private getTerminalLogoUrl(terminal: string | undefined): string {
    const baseUrl = environment.terminalLogoBaseUrl;

    return `${baseUrl}/term-${terminal?.toLowerCase()}.svg`;
  }

}
