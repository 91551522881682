import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'baggageClaimList' })
export class BaggageClaimListPipe implements PipeTransform {

  transform(baggageClaims: any[]): string {
    let baggageClaimList = '';

    if (baggageClaims) {
      const names = baggageClaims.map(claim => claim.name);

      baggageClaimList = names.join(', ');
    }

    return baggageClaimList;
  }

}