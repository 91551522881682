<!-- Bracket Icon -->
<img class="bracket-icon" src="assets/icons/boarding-bracket.svg">

<!-- Boarding Groups -->
<div class="boarding-groups">

  <!-- Boarding Group -->
  <div *ngFor="let boardingGroup of boardingGroups" class="boarding-group" [class.boarding]="boardingGroup.boarding">
    {{ boardingGroup.name }}
  </div>

</div>