import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreUiModule } from '@aoc/core-ui';
import { ModuleId } from '@aoc/sign';

import { BoardingPodiumSideComponent } from './boarding-podium-side.component';
import { BoardingPodiumSideRoutingModule } from './boarding-podium-side-routing.module';
import { BoardingGroupsComponent } from './boarding-groups/boarding-groups.component';
import { BoardingStatusComponent } from './boarding-status/boarding-status.component';
import { FlightComponent } from './flight/flight.component';
import { FlightClosedComponent } from './flight-closed/flight-closed.component';
import { FooterComponent } from './footer/footer.component';
import { GateComponent } from './gate/gate.component';
import { LastCallComponent } from './last-call/last-call.component';
import { SharedModule } from 'src/app/shared/shared.module';

@ModuleId('EnhancedBoardingModule.BoardingPodiumSideModule')
@NgModule({
  declarations: [
    BoardingPodiumSideComponent,
    BoardingGroupsComponent,
    BoardingStatusComponent,
    FlightComponent,
    FlightClosedComponent,
    FooterComponent,
    GateComponent,
    LastCallComponent
  ],
  imports: [
    CommonModule,
    CoreUiModule,
    BoardingPodiumSideRoutingModule,
    SharedModule
  ]
})
export class BoardingPodiumSideModule { }
