import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ModuleId } from '@aoc/sign';

import { YahSlideshowRoutingModule } from './yah-slideshow-routing.module';

@ModuleId('YahSlideshowModule')
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    YahSlideshowRoutingModule
  ]
})
export class YahSlideshowModule { }
