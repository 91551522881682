import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { FlightListRowBaseComponent } from 'src/app/shared/components/flight-list-row/flight-list-row-base.component';
import { WalkTime } from 'src/app/shared/models/walk-time.model';

@Component({
  selector: 'app-flight-list-row',
  templateUrl: './flight-list-row.component.html',
  styleUrls: ['./flight-list-row.component.scss'],
  host: { 'class': 'flight-list-row', '[class]': 'classList' }
})
export class FlightListRowComponent extends FlightListRowBaseComponent implements OnChanges {

  @Input() walkTimes: WalkTime[] | null = [];

  walkTime: WalkTime | undefined;

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes['gate'] || changes['walkTimes']) {
      this.walkTime = this.walkTimes?.find(walkTime => walkTime.gate === this.gate);
    }
  }

}
