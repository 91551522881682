import { Component, Input } from '@angular/core';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-airline-logo',
  templateUrl: './airline-logo.component.html',
  styleUrls: ['./airline-logo.component.scss']
})
export class AirlineLogoComponent {

  @Input() airlineCode: string | undefined;

  baseUrl: string = environment.airlineLogoBaseUrl;

}
