import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { BoardingStatusCode } from 'src/app/shared/enums/boarding-status-code.enum';
import { BoardingGroup } from 'src/app/shared/models/boarding-group.model';

@Component({
  selector: 'app-boarding-status',
  templateUrl: './boarding-status.component.html',
  styleUrls: ['./boarding-status.component.scss'],
  host: { '[class]': 'statusCodeClass' }
})
export class BoardingStatusComponent implements OnChanges {

  @Input() biometric: boolean = false;
  @Input() boardingGroups: BoardingGroup[] = [];
  @Input() statusCode: BoardingStatusCode | undefined;

  boardingMessage: string | undefined;
  statusCodeClass: string | undefined;

  private flightClosedMessage: string = 'Flight Closed';
  private lastCallMessage: string = 'Last Call. All Passengers.';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['statusCode']) {
      const { statusCode } = this;

      this.statusCodeClass = statusCode?.toString().toLowerCase();
    }

    if (changes['boardingGroups'] || changes['statusCode']) {
      const { boardingGroups, statusCode } = this;

      this.boardingMessage = this.getBoardingMessage(boardingGroups, statusCode);
    }
  }

  private getBoardingMessage(boardingGroups: BoardingGroup[], statusCode: BoardingStatusCode | undefined): string | undefined {
    switch (statusCode) {
      case BoardingStatusCode.LastCall:
        return this.lastCallMessage;
      case BoardingStatusCode.Closed:
        return this.flightClosedMessage;
      default:
        return this.getLatestBoardingGroup(boardingGroups)?.name;
    }
  }

  private getLatestBoardingGroup(boardingGroups: BoardingGroup[]): BoardingGroup | undefined {
    let latestBoardingGroup;

    for (let i = boardingGroups.length - 1; i >= 0 && !latestBoardingGroup; i--) {
      const boardingGroup = boardingGroups[i];
      
      latestBoardingGroup = boardingGroup.boarding ? boardingGroup : latestBoardingGroup;
    }

    return latestBoardingGroup;
  }

}
