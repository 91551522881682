import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreUiModule } from '@aoc/core-ui';
import { ModuleId } from '@aoc/sign';

import { StandardRoutingModule } from './standard-routing.module';
import { StandardComponent } from './standard.component';

@ModuleId('EnhancedBoardingModule.GatePierModule.StandardModule')
@NgModule({
  declarations: [
    StandardComponent
  ],
  imports: [
    CommonModule,
    CoreUiModule,
    StandardRoutingModule
  ]
})
export class StandardModule { }
