import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreUiModule } from '@aoc/core-ui';
import { ModuleId } from '@aoc/sign';

import { BoardingTotemComponent } from './boarding-totem.component';
import { BoardingTotemRoutingModule } from './boarding-totem-routing.module';
import { BoardingStatusComponent } from './boarding-status/boarding-status.component';
import { FlightComponent } from './flight/flight.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SharedModule } from 'src/app/shared/shared.module';

@ModuleId('EnhancedBoardingModule.TotemModule')
@NgModule({
  declarations: [
    BoardingTotemComponent,
    BoardingStatusComponent,
    FlightComponent,
    FooterComponent,
    HeaderComponent
  ],
  imports: [
    CommonModule,
    CoreUiModule,
    BoardingTotemRoutingModule,
    SharedModule
  ]
})
export class BoardingTotemModule { }
