import { Component, Input } from '@angular/core';

import { DestinationBackgroundExtension } from '../../enums/destination-background-extension.enum';
import { DestinationBackgroundSize } from '../../enums/destination-background-size.enum';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-destination-background',
  templateUrl: './destination-background.component.html',
  styleUrls: ['./destination-background.component.scss']
})
export class DestinationBackgroundComponent {

  @Input() airportCode: string | undefined;
  @Input() extension: DestinationBackgroundExtension | undefined;
  @Input() size: DestinationBackgroundSize | undefined;

  baseUrl: string = environment.destinationBackgroundBaseUrl;

}
