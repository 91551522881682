import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-row',
  templateUrl: './row.component.html',
  styleUrls: ['./row.component.scss'],
  host: { 'class': 'row' }
})
export class RowComponent {

  @Input() airlineCode: string | undefined;
  @Input() airlineName: string | undefined;
  @Input() domesticTerminal: string | undefined;
  @Input() internationalTerminal: string | undefined;

}
