import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreUiModule } from '@aoc/core-ui';
import { ModuleId } from '@aoc/sign';

import { StandardComponent } from './standard.component';
import { StandardRoutingModule } from './standard-routing.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HeaderRowComponent } from './header-row/header-row.component';
import { RowComponent } from './row/row.component';
import { SharedModule } from 'src/app/shared/shared.module';

@ModuleId('RccAirlinesModule.StandardModule')
@NgModule({
  declarations: [
    StandardComponent,
    FooterComponent,
    HeaderComponent,
    HeaderRowComponent,
    RowComponent
  ],
  imports: [
    CommonModule,
    CoreUiModule,
    StandardRoutingModule,
    SharedModule
  ]
})
export class StandardModule { }
