import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CustomsModule } from './customs/customs.module';

const routes: Routes = [
  {
    path: 'customs',
    loadChildren: () => CustomsModule
  },
  {
    path: 'standard',
    loadChildren: () => import('./standard/standard.module').then(m => m.StandardModule)
  },
  {
    path: 'two-by-two-landscape',
    loadChildren: () => import('./two-by-two-landscape/two-by-two-landscape.module').then(m => m.TwoByTwoLandscapeModule)
  },
  {
    path: 'video-wall',
    loadChildren: () => import('./video-wall/video-wall.module').then(m => m.VideoWallModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FidsRoutingModule { }
