import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BoardingMessageComponent } from './boarding-message/boarding-message.component';
import { HeaderComponent } from './header/header.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    BoardingMessageComponent,
    HeaderComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    BoardingMessageComponent,
    HeaderComponent
  ]
})
export class BoardingSharedModule { }
