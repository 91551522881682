import { Component, Input } from '@angular/core';

import { BoardingStatusCode } from 'src/app/shared/enums/boarding-status-code.enum';
import { BoardingGroup } from 'src/app/shared/models/boarding-group.model';

@Component({
  selector: 'app-boarding-status',
  templateUrl: './boarding-status.component.html',
  styleUrls: ['./boarding-status.component.scss']
})
export class BoardingStatusComponent {

  @Input() boarding: boolean = false;
  @Input() boardingGroups: BoardingGroup[] = [];
  @Input() busBoarding: boolean = false;
  @Input() statusCode: BoardingStatusCode | undefined;

}
