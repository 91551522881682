<ng-container *ngIf="flight && boardingInfo && boardingInfo.valid">

  <!-- Main Content -->
  <main>

    <!-- Header -->
    <app-header
      class="header"
      layout="short"
      [airline]="flight.airlineName"
      [airlineCode]="flight.airlineCode"
      [destination]="flight.destinationAirport"
      [flightNumber]="flight.flightNumber">
    </app-header>

    <!-- Content -->
    <div class="content">

      <!-- Arrow -->
      <img class="arrow" src="assets/icons/boarding-arrow-down-left.svg">

      <!-- Boarding Message -->
      <app-boarding-message class="boarding-message" [boardingInfo]="boardingInfo" [door]="door"></app-boarding-message>

    </div>

  </main>

  <!-- Footer -->
  <footer></footer>

</ng-container>

<!-- No Flight Slideshow -->
<aoc-slideshow
  *ngIf="!boardingInfo?.valid && noFlightPlaylist"
  class="no-flight-slideshow"
  [playlist]="noFlightPlaylist">
</aoc-slideshow>