import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BehindBoardingDoorsModule } from './behind-boarding-doors/behind-boarding-doors.module';
import { BoardingDoorsModule } from './boarding-doors/boarding-doors.module';
import { GatePierLowerEscalatorModule } from './gate-pier-lower-escalator/gate-pier-lower-escalator.module';
import { GatePierLowerWideModule } from './gate-pier-lower-wide/gate-pier-lower-wide.module';
import { GatePierUpperWideModule } from './gate-pier-upper-wide/gate-pier-upper-wide.module';
import { GatePierUpperModule } from './gate-pier-upper/gate-pier-upper.module';

const routes: Routes = [
  {
    path: 'behind-boarding-doors',
    loadChildren: () => BehindBoardingDoorsModule
  },
  {
    path: 'boarding-doors',
    loadChildren: () => BoardingDoorsModule
  },
  {
    path: 'gate-pier-lower-escalator',
    loadChildren: () => GatePierLowerEscalatorModule
  },
  {
    path: 'gate-pier-lower-wide',
    loadChildren: () => GatePierLowerWideModule
  },
  {
    path: 'gate-pier-upper',
    loadChildren: () => GatePierUpperModule
  },
  {
    path: 'gate-pier-upper-wide',
    loadChildren: () => GatePierUpperWideModule
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BoardingRoutingModule { }
