import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ModuleId } from '@aoc/sign';

import { RccAirlinesRoutingModule } from './rcc-airlines-routing.module';

@ModuleId('RccAirlinesModule')
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RccAirlinesRoutingModule
  ]
})
export class RccAirlinesModule { }
