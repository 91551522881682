import { Component } from '@angular/core';

import { ComponentId } from '@aoc/sign';

import { GidsBaseComponent } from '../gids-base.component';
import { DestinationBackgroundExtension } from 'src/app/shared/enums/destination-background-extension.enum';
import { DestinationBackgroundSize } from 'src/app/shared/enums/destination-background-size.enum';

@ComponentId('GidsModule.BoardingWideModule.BoardingWideComponent')
@Component({
  selector: 'app-boarding-wide',
  templateUrl: './boarding-wide.component.html',
  styleUrls: ['./boarding-wide.component.scss']
})
export class BoardingWideComponent extends GidsBaseComponent {

  override enableBoardingStatus: boolean = true;

  cityscapeBackgroundExtension: DestinationBackgroundExtension = DestinationBackgroundExtension.PNG;
  cityscapeBackgroundSize: DestinationBackgroundSize = DestinationBackgroundSize.VideoBarShort;

}
