import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { FlightTypeEnum } from '@aoc/data-models';

@Component({
  selector: 'app-flight-list-header-row',
  templateUrl: './flight-list-header-row.component.html',
  styleUrls: ['./flight-list-header-row.component.scss'],
  host: { 'class': 'flight-list-row' }
})
export class FlightListHeaderRowComponent implements OnChanges {

  @Input() flightType: FlightTypeEnum | undefined;

  airportColumnTitle: string | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    const flightType = changes['flightType']?.currentValue;

    if (changes['flightType']) {
      this.airportColumnTitle = this.getAirportColumnTitle(flightType);
    }
  }

  private getAirportColumnTitle(flightType: FlightTypeEnum | undefined): string | undefined {
    let airportColumnTitle;

    if (flightType === FlightTypeEnum.arrivals) {
      airportColumnTitle = 'Origin';
    }

    if (flightType === FlightTypeEnum.departures) {
      airportColumnTitle = 'Destination';
    }

    return airportColumnTitle;
  }

}
