import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { Asset } from '@aoc/data-models';
import { DmsDataService } from '@aoc/dms-data';

import { RccAirline } from '../models/rcc-airline.model';

@Injectable()
export class RccAirlinesService {

  constructor(
    private dmsDataService: DmsDataService
  ) {}

  getRccAirlines(): Observable<RccAirline[]> {
    return this.dmsDataService.getAssetsByAssetTypeId(6).pipe(
      map(assets => assets.filter(asset => asset.name !== 'Airline Ticketing')),
      map(assets => assets.filter(asset => asset.properties.get('Operating BOS'))),
      map(assets => assets.map(asset => this.toRccAirline(asset)))
    );
  }

  private toRccAirline(asset: Asset): RccAirline {
    const defaultDomesticDeparturesTerminal = asset.properties.get('Default Domestic Departures Terminal');
    const defaultInternationalDeparturesTerminal = asset.properties.get('Default International Departures Terminal');

    return {
      airlineCode: asset.properties.get('IATA Code'),
      airlineName: asset.name,
      domesticTerminal: defaultDomesticDeparturesTerminal || defaultInternationalDeparturesTerminal,
      internationalTerminal: defaultInternationalDeparturesTerminal || defaultDomesticDeparturesTerminal
    };
  }

}