import { Component, OnDestroy, OnInit } from '@angular/core';
import { map, mergeMap, Observable, Subject, takeUntil, timer } from 'rxjs';

import { ConfigService } from '@aoc/config';
import { ComponentId, SignComponent } from '@aoc/sign';

import { RccAirlines } from '../rcc-airlines.model';
import { RccAirline } from 'src/app/shared/models/rcc-airline.model';
import { RccAirlinesService } from 'src/app/shared/services/rcc-airlines.service';

import { environment } from 'src/environments/environment';

@ComponentId('RccAirlinesModule.StandardModule.StandardComponent')
@Component({
  selector: 'app-standard',
  templateUrl: './standard.component.html',
  styleUrls: ['./standard.component.scss']
})
export class StandardComponent extends SignComponent implements OnDestroy, OnInit {

  footerText: string | undefined;
  rccAirlines: RccAirline[] = [];
  title: string | undefined;

  private pageSize: number = 0;
  private pageStartIndex: number = 0;
  private rccAirlinesUpdateIntervalMs: number = environment.rccAirlinesUpdateIntervalSeconds * 1000;
  private unsubscribe: Subject<any> = new Subject();

  constructor(
    private configService: ConfigService,
    private rccAirlinesService: RccAirlinesService
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
  }

  async ngOnInit(): Promise<any> {
    const config = await this.configService.get<RccAirlines>({});

    const pageNumber = config.pageNumber - 1;
    const pageSize = config.airlinesPerPage;

    this.footerText = config.footerText;
    this.pageSize = pageSize;
    this.pageStartIndex = pageNumber * pageSize;
    this.title = config.title;

    this.initRccAirlinesDataFeed();
  }

  private compareRccAirlines(rccAirlineA: RccAirline, rccAirlineB: RccAirline): number {
    const airlineA = rccAirlineA.airlineName;
    const airlineB = rccAirlineB.airlineName;

    return airlineA.localeCompare(airlineB);
  }

  private getRccAirlines(): Observable<RccAirline[]> {
    return this.rccAirlinesService.getRccAirlines().pipe(
      map(rccAirlines => rccAirlines.sort((a, b) => this.compareRccAirlines(a, b))),
      map(rccAirlines => rccAirlines.splice(this.pageStartIndex, this.pageSize))
    );
  }

  private initRccAirlinesDataFeed(): void {
    const updateInterval = this.rccAirlinesUpdateIntervalMs;

    const rccAirlinesDataFeed = timer(0, updateInterval).pipe(
      mergeMap(() => this.getRccAirlines()),
      takeUntil(this.unsubscribe)
    );

    rccAirlinesDataFeed.subscribe(rccAirlines => this.rccAirlines = rccAirlines);
  }

}
